<div class="page">
  <div class="test">
    <div class="left-side">
      <app-countdown-timer [countdownInSeconds]="examsInformation?.remainingTimeSeconds || 0"
                           style="z-index: 2;position: relative"></app-countdown-timer>
      <div class="text mt-24" style="z-index: 2;position: relative">Questions</div>
      <div class="all-question mt-16" style="z-index: 2;position: relative">
        @for (i of exams; track i.id; let nI = $index) {
          <div (click)="onQuestionClicked(nI)" [class.number--done]="examFormArray.value[nI].selected"
               class="number">{{ nI + 1 }}
          </div>
        }
      </div>
      <div class="w-100">
        <button (click)="onSubmit()" class="w-100 mt-24" nz-button>Submit</button>
      </div>
    </div>
    <div class="right-side h-100">
      <form [formGroup]="form" class="h-100">
        <ng-container formArrayName="exams">
          @if (examFormArray.controls.length) {
            <div [formGroup]="$any(examFormArray.controls[currentQuestionIndex])" class="w-100 h-100">
              <app-question-card (nextClicked)="onNextChoice()"
                                 (prevClicked)="onPrevChoice()"
                                 (selected)="onNextChoice()"
                                 [countdownInSeconds]="examsInformation?.remainingTimeSeconds || 0"
                                 [currentQuestionIndex]="currentQuestionIndex+1"
                                 [form]="$any(examFormArray.controls[currentQuestionIndex])"
                                 [hideNext]="currentQuestionIndex === exams.length-1"
                                 [hidePrev]="currentQuestionIndex===0"
                                 [question]="exams[currentQuestionIndex]"
                                 [totalQuestions]="exams.length"></app-question-card>
            </div>
          }
        </ng-container>

      </form>
    </div>
  </div>

</div>
