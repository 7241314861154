import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription, takeWhile} from "rxjs";
import {DecimalPipe} from "@angular/common";

@Component({
  selector: 'app-countdown-timer',
  standalone: true,
  imports: [
    DecimalPipe
  ],
  templateUrl: './countdown-timer.component.html',
  styleUrl: './countdown-timer.component.scss'
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() countdownInSeconds: number = 3600; // Example: 1 hour = 3600 seconds
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  private subscription!: Subscription;

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown(): void {
    this.calculateTimeUnits(); // Initial calculation
    this.subscription = interval(1000)
      .pipe(
        takeWhile(() => this.countdownInSeconds > 0) // Stop when the countdown reaches 0
      )
      .subscribe(() => {
        this.countdownInSeconds--;
        this.calculateTimeUnits(); // Recalculate hours, minutes, seconds
      });
  }

  // Convert seconds into hours, minutes, and seconds
  calculateTimeUnits(): void {
    this.hours = Math.floor(this.countdownInSeconds / 3600); // Get the hours
    this.minutes = Math.floor((this.countdownInSeconds % 3600) / 60); // Get the remaining minutes
    this.seconds = this.countdownInSeconds % 60; // Get the remaining seconds
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Cleanup the subscription
    }
  }
}
