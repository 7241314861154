<div style="margin-bottom: 16px">
  <div class="d-flex d-jus-between">
    <button (click)="onBack()" nz-button>
      <span nz-icon nzTheme="outline" nzType="arrow-left"></span>Back
    </button>
    <button (click)="onOpenTools()" class="btn-system" nz-button nzType="primary">
      <span nz-icon nzTheme="outline" nzType="tool"></span>Tools
    </button>
  </div>
</div>
<div #scrollContainer class="candidate fade-in-text">
  <div class="candidate__detail">
    <div class="candidate__detail__name">Applied Position: {{ candidate?.jobTitle }}</div>
    <hr />

    <div class="candidate__detail__image mt-16 d-flex d-jus-between">
      <img [src]="candidate?.image" alt="candidate" />
    </div>

    <div class="mt-16 candidate__detail__name">{{ candidate?.name }}</div>
    <div class="w-100 d-flex a-delay-1000">
      <div class="candidate__detail__label">Registered Date:</div>
      <div class="candidate__detail__data">
        {{ candidate?.createdDate | date: 'MMM d, y, h:mm a' }}
      </div>
    </div>
    <div class="w-100 d-flex">
      <div class="candidate__detail__label">Phone number:</div>
      <div class="candidate__detail__data">{{ candidate?.mobile }}</div>
    </div>
    <div class="w-100 d-flex a-delay-1000">
      <div class="candidate__detail__label">Email:</div>
      <div class="candidate__detail__data">{{ candidate?.mail }}</div>
    </div>

    @if (candidate?.examScore !== null && candidate?.examScore !== undefined) {
      <div class="mt-24">
        <h2>Score</h2>
        <div class="d-flex mt-24" style="width: 100%">
          <div class="d-flex" style="width: 100%; gap: 24px; align-items: center">
            <div>
              <nz-progress
                [nzPercent]="candidate?.totalScore"
                [nzShowInfo]="true"
                [nzStatus]="'normal'"
                [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                nzType="circle"
                style="width: 100%"
              ></nz-progress>
            </div>
            <div class="w-100">
              <div>
                <nz-row>
                  <div nz-col nzSpan="5">Assessment</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.examScore"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="5">Resume</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.overallMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-24">
        <h2>Score Detail</h2>
        <div class="d-flex mt-24" style="width: 100%">
          <div class="d-flex" style="width: 100%; gap: 24px; align-items: center">
            <div class="w-100">
              <div>
                <nz-row>
                  <div nz-col nzSpan="5">Date of Examination</div>
                  <div nz-col nzSpan="15">
                    {{ candidate?.startAssessDateTime | date: 'fullDate' }}
                    at {{ candidate?.startAssessDateTime | date: 'shortTime' }}
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="5">Skills</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.skillMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="5">Experience</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.expMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="5">Education</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.educationMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="5">Soft Skills</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.softSkillMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="5">Job Title</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.jobTitleMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="mt-24">
        <h2>Resume Score</h2>
        <div class="d-flex mt-24" style="width: 100%">
          <div class="d-flex" style="width: 100%; gap: 24px; align-items: center">
            <div>
              <nz-progress
                [nzPercent]="candidate?.overallMatch"
                [nzShowInfo]="true"
                [nzStatus]="'normal'"
                [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                nzType="circle"
                style="width: 100%"
              ></nz-progress>
            </div>
            <div class="w-100">
              <div>
                <nz-row>
                  <div nz-col nzSpan="4">Skills</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.skillMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="4">Experience</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.expMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="4">Education</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.educationMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="4">Soft Skills</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.softSkillMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
              <div>
                <nz-row>
                  <div nz-col nzSpan="4">Job Title</div>
                  <div nz-col nzSpan="15">
                    <nz-progress
                      [nzPercent]="candidate?.jobTitleMatch"
                      [nzStatus]="'normal'"
                      [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                      style="width: 100%; float: left"
                    ></nz-progress>
                  </div>
                </nz-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    <div class="comments" style="margin-top: 34px">
      <div>
        <h2>Comments</h2>
      </div>
      <app-comment
        [dateTime]="candidate?.startAssessDateTime"
        [text]="candidate?.conclusion"
        author="AI Recruit"
        authorShortName="AI"
      ></app-comment>

      @for (comment of candidate?.interviewerComment; track comment) {
        <app-comment
          (edited)="onCommentUpdate(comment.id, $event)"
          (deleted)="onCommentDelete(comment.id)"
          [dateTime]="comment.lastModifiedDate || comment.createdDate"
          [text]="comment.comment"
          author="Interviewer"
          authorShortName="IV"
        ></app-comment>
      }

      <div class="mt-16">
        <textarea [(ngModel)]="comment" nz-input placeholder="" rows="3"></textarea>
        <button (click)="onComment()" class="btn-system w-100 mt-16" nz-button>Comment</button>
      </div>
    </div>
  </div>

  <div *ngIf="pdfSrc" class="candidate__resume">
    <pdf-viewer
      [autoresize]="true"
      [fit-to-page]="true"
      [original-size]="false"
      [render-text]="true"
      [show-all]="true"
      [src]="pdfSrc"
      [stick-to-page]="true"
    ></pdf-viewer>
  </div>
</div>

<nz-drawer
  (nzOnClose)="close()"
  [nzClosable]="false"
  [nzPlacement]="'right'"
  [nzVisible]="toolsVisible"
  nzTitle="Tools"
>
  <ng-container *nzDrawerContent>
    <div class="tool-menu">
      <div class="tool-menu__item">
        <button (click)="onShowGuideLine()" nz-button>
          <span style="margin-right: 8px"> <fa-icon icon="book"></fa-icon></span>
          Interview Guide
        </button>
      </div>
      <div class="tool-menu__item">
        <button (click)="onGenerateRetestLink()" [disabled]="!candidate?.examScore" nz-button>
          <span style="margin-right: 8px"> <fa-icon icon="flask-vial"></fa-icon></span>Generate
          Retest
        </button>
      </div>
      <div class="tool-menu__item">
        <button (click)="onChangeCandidateStatus()" nz-button>
          <span style="margin-right: 8px"> <fa-icon icon="angle-double-right"></fa-icon></span
          >Change Candidate Status
        </button>
      </div>
    </div>
  </ng-container>
</nz-drawer>

<nz-modal
  (nzOnCancel)="onCloseRetest()"
  [(nzVisible)]="retestVisible"
  nzTitle="Gernerate Retest Link"
>
  <ng-container *nzModalContent>
    <input [(ngModel)]="retestLink" disabled="true" nz-input type="text" />
  </ng-container>
  <div *nzModalFooter>
    <button (click)="onCopy()" class="btn-system" nz-button nzType="default">Copy link</button>
    <button (click)="onCloseRetest()" nz-button nzType="default">Close</button>
  </div>
</nz-modal>

<nz-modal
  (nzOnCancel)="onCloseGuideLine()"
  [(nzVisible)]="guidelineVisible"
  nzTitle="Interview Guideline"
  nzWidth="70%"
>
  <ng-container *nzModalContent>
    <nz-radio-group [(ngModel)]="guidelineLang">
      <label nz-radio nzValue="TH">TH</label>
      <label nz-radio nzValue="EN">EN</label>
    </nz-radio-group>
    <button (click)="onGetGuideLine()" [nzLoading]="guidelineLoading" class="btn-system" nz-button>
      Generate
    </button>
    <nz-spin [nzSpinning]="guidelineLoading">
      <div [innerHTML]="guideline" style="white-space: pre; padding: 16px; text-wrap: pretty"></div>
    </nz-spin>
  </ng-container>
  <div *nzModalFooter>
    <button (click)="onCloseGuideLine()" nz-button nzType="default">Close</button>
  </div>
</nz-modal>

<nz-modal
  (nzOnCancel)="onCloseChangeStatus()"
  [(nzVisible)]="changeStatusModalVisible"
  nzTitle="Candidate Status"
  nzWidth="340px"
>
  <ng-container *nzModalContent>
    <nz-select [(ngModel)]="candidateStatus" class="w-100">
      <nz-option nzLabel="Awaiting Interview" nzValue="READY_FOR_INTERVIEW"></nz-option>
      <nz-option nzLabel="Interviews Finished" nzValue="PENDING_DECISION"></nz-option>
      <nz-option nzLabel="Not Selected" nzValue="NOT_PASS_INTERVIEW"></nz-option>
      <nz-option nzLabel="Onboarded" nzValue="PASS_INTERVIEW"></nz-option>
    </nz-select>
  </ng-container>
  <div *nzModalFooter>
    <button
      (click)="onUpdateChangeStatus()"
      [nzLoading]="changeStatusLoading"
      class="btn-system"
      nz-button
      nzType="default"
    >
      Update
    </button>
    <button (click)="onCloseChangeStatus()" nz-button nzType="default">Close</button>
  </div>
</nz-modal>
