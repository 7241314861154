import { Component, OnInit } from '@angular/core';
import { Location, NgForOf, NgIf } from '@angular/common';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CompanyService } from '../../../core/services/company/company.service';
import { JobService } from '../../../core/services/job/job.service';
import { getCurrentDomainWithoutSubPath } from '../../../helpers/url';
import { ActivatedRoute, Router } from '@angular/router';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { Clipboard } from '@angular/cdk/clipboard';
import { finalize } from 'rxjs';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NzSpinComponent } from 'ng-zorro-antd/spin';

interface Company {
  id: string;
  name: string;
}

@Component({
  selector: 'app-edit-job',
  standalone: true,
  imports: [
    NgForOf,
    NzInputModule,
    NzFormModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormsModule,
    NzButtonModule,
    NgIf,
    NzIconDirective,
    NzSpinComponent,
  ],
  templateUrl: './edit-job.component.html',
  styleUrl: './edit-job.component.scss',
})
export class EditJobComponent implements OnInit {
  form: FormGroup;
  companies: Company[] = [];
  id: string = '';
  loading = false;
  activate = false;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private jobService: JobService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private location: Location,
    private clipboard: Clipboard,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      company: ['', Validators.required],
      prompt: [''],
      res: this.formBuilder.array([this.formBuilder.control('', Validators.required)]),
      qualifications: this.formBuilder.array([this.formBuilder.control('', Validators.required)]),
    });
  }

  get resArray(): FormArray {
    return this.form.get('res') as FormArray;
  }

  get quaArray(): FormArray {
    return this.form.get('qualifications') as FormArray;
  }

  onBack() {
    this.router.navigateByUrl('/career');
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.loading = true;
    this.companyService.companyList().subscribe({
      next: list => {
        this.companies = list.data.map(i => {
          return {
            id: i.companyId,
            name: i.companyName,
          };
        });
        this.form.get('company')?.patchValue(this.companies[0].id);
      },
    });
    this.jobService.getJobDetail(this.id).subscribe({
      next: response => {
        const data = response.data;
        this.updateFormByAIData(data.resp || [], data.jobQ || []);
        this.form.get('title')?.patchValue(data.jobTitle);
        this.form.get('prompt')?.patchValue(data.latestPrompt);
        // this.unDisableAll()
        this.loading = false;
        if (data.status === 'ACTIVE') {
          this.disableAll();
        }
        this.activate = data.status !== 'DRAFT';
      },
    });
  }

  onCreate() {}

  disableAll() {
    Object.values(this.form.controls).forEach(control => {
      if (control instanceof FormArray) {
        Object.values(control.controls).forEach(i => {
          i.disable();
        });
      } else if (control instanceof FormControl) {
        control.disable();
      }
    });
  }

  unDisableAll() {
    Object.values(this.form.controls).forEach(control => {
      if (control instanceof FormArray) {
        Object.values(control.controls).forEach(i => {
          i.enable();
        });
      } else if (control instanceof FormControl) {
        console.log('enable');
        control.enable();
      }
    });
  }

  onGenerate() {
    if (this.form.get('title')?.valid && this.form.get('prompt')?.valid) {
      this.loading = true;
      this.disableAll();
      console.log('valid');
      this.jobService
        .generateDescByAI({
          jobDescId: this.id,
          jobTitle: this.form.value.title,
          prompt: this.form.value.prompt,
          companyId: this.form.value.company,
          language: 'TH',
        })
        .subscribe({
          next: res => {
            this.id = res.data.id;
            this.updateFormByAIData(res.data.resp || [], res.data.jobQ || []);
            this.unDisableAll();
            this.loading = false;
            this.notification.success('AI Generated Successfully', 'Please review response');
          },
        });
    } else {
      ['title', 'prompt'].forEach(i => {
        const control = this.form.get(i);
        if (control && control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  onResRemoveIndex(index: number) {
    this.resArray.removeAt(index);
  }

  onQuaRemoveIndex(index: number) {
    this.quaArray.removeAt(index);
  }

  onSave(): void {
    if (this.id) {
      this.disableAll();
      this.loading = true;
      this.jobService
        .updateJob({
          companyId: this.form.value.company,
          id: this.id,
          resp: this.form.value.qualifications,
          jobTitle: this.form.value.title,
          jobQ: this.form.value.res,
        })
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: () => {
            this.notification.success('Success', 'Your Data is saved successfully.');
            this.unDisableAll();
            this.loading = false;
          },
          error: e => {
            this.notification.error('Error', e.error.errorMsg);
          },
        });
    }
  }

  onViewRanking() {
    this.router.navigateByUrl(`/ranking/${this.id}`);
  }

  onPreview() {
    const domain = getCurrentDomainWithoutSubPath();
    window.open(domain + '/register/' + this.id, '_blank');
  }

  onCopy() {
    const domain = getCurrentDomainWithoutSubPath();
    const url = domain + '/register/' + this.id;
    this.clipboard.copy(url);
    this.notification.success('Copied', '');
  }

  onActivate() {
    this.jobService.jobActivate(this.id).subscribe({
      next: () => {
        this.notification.success('Success', 'Your Job is activated.');
        this.activate = true;
        this.disableAll();
      },
      error: e => {
        this.notification.error(`Error ${e.error.errorMsg}`, '');
      },
    });
  }

  onResAdd() {
    this.resArray.push(this.formBuilder.control('', Validators.required));
  }

  onQualificationAdd() {
    this.quaArray.push(this.formBuilder.control('', Validators.required));
  }

  private updateFormByAIData(res: string[], qua: string[]) {
    this.resArray.clear();
    this.quaArray.clear();

    res.forEach(i => {
      this.resArray.push(this.formBuilder.control(i, Validators.required));
    });

    qua.forEach(i => {
      this.quaArray.push(this.formBuilder.control(i, Validators.required));
    });

    if (this.resArray.length === 0) {
      this.resArray.push(this.formBuilder.control('', Validators.required));
    }

    if (this.quaArray.length === 0) {
      this.quaArray.push(this.formBuilder.control('', Validators.required));
    }
  }
}
