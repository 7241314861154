import { Component, OnInit } from '@angular/core';
import { JsonPipe, LowerCasePipe, NgForOf, UpperCasePipe } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzTypographyComponent, NzTypographyModule } from 'ng-zorro-antd/typography';
import { ExaminationService } from '../../../core/services/examination/examination.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { UpdateJobAssessPayload } from '../../../core/services/examination/examination.model';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { finalize } from 'rxjs';
import { NzCardComponent } from 'ng-zorro-antd/card';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NzRadioComponent, NzRadioGroupComponent } from 'ng-zorro-antd/radio';
import { RouteHistoryService } from '../../../shared/services/route-history/route-history.service';

@Component({
  selector: 'app-edit-examination',
  standalone: true,
  imports: [
    NzButtonModule,
    NzIconModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    NzTypographyComponent,
    NzTypographyModule,
    NgForOf,
    JsonPipe,
    UpperCasePipe,
    NzSelectModule,
    LowerCasePipe,
    NzSpinModule,
    NzCardComponent,
    NzRadioComponent,
    NzRadioGroupComponent,
  ],
  templateUrl: './edit-examination.component.html',
  styleUrl: './edit-examination.component.scss',
})
export class EditExaminationComponent implements OnInit {
  examinationForm: FormGroup;
  loading = false;
  releaseId = '';
  jobId = '';

  constructor(
    private fb: FormBuilder,
    private examinationService: ExaminationService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private routeHistoryService: RouteHistoryService,
    private router: Router
  ) {
    this.examinationForm = fb.group({
      name: ['', Validators.required],
      time: ['', Validators.required],
      prompt: ['', Validators.required],
      language: ['TH'],
      questionsForGen: ['', Validators.required],
      exams: this.fb.array([]),
    });
  }

  get examinationArray(): FormArray {
    return this.examinationForm.get('exams') as FormArray;
  }

  ngOnInit() {
    this.loading = true;
    const params = this.route.snapshot.params;
    this.releaseId = params['releaseId'];
    this.jobId = params['jobId'];
    this.examinationService
      .getExamination(this.route.snapshot.params['id'])
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          const data = response.data;
          this.examinationForm.patchValue({
            name: data.name,
            time: data.totalTime,
            prompt: data.latestPrompt,
            questionsForGen: data.totalNoQuestion,
          });
          data.jobAssessChoiceRes.forEach(question => {
            this.addQuestion(
              question.question,
              question.correctAnswer,
              Object.values(question.choice)
            );
          });
        },
        error: e => {
          this.notificationService.apiError(e);
        },
      });
  }

  onBack() {
    // this.routeHistoryService.back()
    this.router.navigateByUrl(`/examination/release/${this.releaseId}/${this.jobId}`);
  }

  addQuestion(question: string, correctAnswer: string, choicesAnswer: string[]) {
    this.examinationArray.push(
      this.fb.group({
        question: [question, Validators.required],
        correctAnswer: [correctAnswer, Validators.required],
        choicesAnswer: this.fb.array(this.addDefaultChoice(choicesAnswer)),
      })
    );
  }

  onAddQuestion() {
    this.examinationArray.push(
      this.fb.group({
        question: ['', Validators.required],
        correctAnswer: ['', Validators.required],
        choicesAnswer: this.fb.array(this.addDefaultChoice()),
      })
    );
  }

  addDefaultChoice(choice?: string[]): FormGroup[] {
    const fs: FormGroup[] = [];
    const defaultChoice = ['a', 'b', 'c', 'd'];
    if (choice) {
      defaultChoice.forEach((c, index) => {
        fs.push(
          this.fb.group({
            id: [c, Validators.required],
            answer: [choice[index], Validators.required],
          })
        );
      });
    } else {
      defaultChoice.forEach(choice => {
        fs.push(
          this.fb.group({
            id: [choice, Validators.required],
            answer: ['', Validators.required],
          })
        );
      });
    }

    return fs;
  }

  onSave() {
    this.loading = true;
    const payload: UpdateJobAssessPayload = {
      id: this.route.snapshot.params['id'],
      name: this.examinationForm.value.name,
      totalTime: this.examinationForm.value.time,
      questions: this.examinationForm.value.exams.map((i: any) => {
        return {
          question: i.question,
          correctAnswer: i.correctAnswer,
          choice: i.choicesAnswer.reduce(
            (acc: any, curr: any) => {
              acc[curr.id] = curr.answer;
              return acc;
            },
            {} as Record<string, string>
          ),
        };
      }),
    };
    this.examinationService
      .updateJobAssess(payload)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success('Success', 'Examination updated');
        },
        error: e => {
          this.notificationService.apiError(e);
        },
      });
  }

  onDelete(index: number) {
    this.examinationArray.removeAt(index);
  }

  onGenerate() {
    this.loading = true;
    this.examinationService
      .generateExamination({
        prompt: this.examinationForm.value.prompt,
        jobAssessId: this.route.snapshot.params['id'],
        noQuestion: this.examinationForm.value.questionsForGen,
        language: this.examinationForm.value.language,
      })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          const data = response.data;
          this.examinationForm.patchValue({
            name: data.name,
            time: data.totalTime,
            prompt: data.latestPrompt,
          });
          this.examinationArray.clear();
          data.jobAssessChoiceRes.forEach(question => {
            this.addQuestion(
              question.question,
              question.correctAnswer,
              Object.values(question.choice)
            );
          });
          this.notificationService.success('Auto saved successfully', 'Examination updated');
        },
        error: e => {
          this.notificationService.apiError(e);
        },
      });
  }
}
