<div style="margin-bottom: 16px">
  <button (click)="onBack()" nz-button><span nz-icon nzTheme="outline" nzType="arrow-left"></span>Back</button>
</div>
<div class="ranking-page">
  <div class="ranking-page__content">
    <div><h2>Ranking</h2>

    </div>
    <nz-table #sortTable [nzData]="listOfData" [nzLoading]="loading" nzTableLayout="fixed">
      <thead>
      <tr>
        <th *ngFor="let column of listOfColumn" [nzAlign]="column.align" [nzShowSort]="column?.showSort"
            [nzSortFn]="column.compare"
            [nzWidth]="column.width">
          {{ column.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of sortTable.data">
        <td>{{ data.name }}</td>
        <td [nzAlign]="'center'">
          @switch (data.status) {
            @case ('READY_FOR_INTERVIEW') {
              <nz-tag class="btn-system--normal">{{ data.statusDisplay }}</nz-tag>
            }
            @case ('PENDING_DECISION') {
              <nz-tag class="btn-system--common">{{ data.statusDisplay }}</nz-tag>
            }
            @case ('NOT_PASS_INTERVIEW') {
              <nz-tag class="btn-system--danger">{{ data.statusDisplay }}</nz-tag>
            }
            @case ('PASS_INTERVIEW') {
              <nz-tag class="btn-system">{{ data.statusDisplay }}</nz-tag>
            }
          }
        </td>
        <td [nzAlign]="'center'">
          @if (data.examsScore) {
            {{ data.examsScore }}%
          } @else {
            -
          }
        </td>
        <td [nzAlign]="'center'">{{ data.resumeScore }}%</td>
        <td [nzAlign]="'center'">{{ data.totalScore }}%</td>
        <td>
          <button [routerLink]="['/','candidate',data.id]" nz-button><span nz-icon nzTheme="outline"
                                                                           nzType="file-text"></span>Detail
          </button>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
