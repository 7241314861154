<div class="page">
  <div class="test">
    <div class="test__detail">
      <div class="d-flex d-jus-end" style="flex-direction: column">
        <div class="test__detail__subtitle">Number of Questions: {{ information?.totalQuestion }}</div>
        <div class="test__detail__subtitle">Duration: {{ information?.minutes }} Minutes</div>
      </div>
      <div class="test__detail__title">Pretest : {{ information?.jobTitle }}
        <div class="test__detail__subtitle">{{ information?.companyName }}</div>
      </div>

      <div class="test__detail__description mt-24 sarabun-regular">
        ข้อสอบนี้เป็นข้อสอบปรนัยที่มี 4 ตัวเลือกในแต่ละข้อ เมื่อคุณเริ่มทำข้อสอบ ระบบจะเริ่มนับเวลาถอยหลังทันที
        และจะไม่มีการรีเซ็ตเวลาใหม่ในทุกกรณี กรุณาตรวจสอบความพร้อมของคุณก่อนเริ่มทำข้อสอบให้เรียบร้อย
        เพราะเมื่อเวลาถูกเริ่มนับแล้ว คุณจะไม่สามารถหยุดหรือตั้งเวลาใหม่ได้อีก
      </div>
    </div>
    <div class="test__right-side">
      <div (click)="onStart()" class="test__right-side__start-button sarabun-regular">เริ่มทำข้อสอบ</div>
    </div>
  </div>

</div>
