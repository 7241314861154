@if (question && form) {
  <div class="card d-flex">
    <div>
      @if (mobileMode) {
        <app-countdown-timer [countdownInSeconds]="countdownInSeconds"></app-countdown-timer>
      }
      <div class="d-flex question-title" style="gap: 16px">
        <div class="number">{{ question.runningNumber }}</div>
        <div class="question">{{ question.question }}</div>
      </div>
      <div class="choice-list mt-24">
        @for (choice of question.choices; track question.id) {
          @if (form.value.selected === choice.id) {
            <div class="choice choice--selected"
                 (click)="onSelect(choice.id)">{{ (choice.id | uppercase) + '. ' + choice.label }}
            </div>
          } @else {
            <div class="choice"
                 (click)="onSelect(choice.id)">{{ (choice.id | uppercase) + '. ' + choice.label }}
            </div>
          }
        }
      </div>
    </div>

    <div>
      <div class="panel">
        <div class="mobile-panel">
          <div>
            {{ currentQuestionIndex + '/' + totalQuestions }}
          </div>
        </div>
        <div class="d-flex" style="gap: 16px">
          @if (!hidePrev) {
            <button nzType="default" (click)="onPrev()" nz-button class="sarabun-regular"><span nz-icon
                                                                                                nzType="arrow-left"
                                                                                                nzTheme="outline"></span>
            </button>
          }

          @if (!hideNext) {
            <button nzType="default" [disabled]="mobileMode ? !form.value.selected: false" (click)="onNext()"
                    nz-button
                    class="sarabun-regular"><span
              nz-icon
              nzType="arrow-right"
              nzTheme="outline"></span>
            </button>
          }
        </div>
      </div>

    </div>
  </div>
}
