import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {NzButtonComponent} from "ng-zorro-antd/button";
import {CountdownTimerComponent} from "../countdown-timer/countdown-timer.component";
import {FormGroup} from "@angular/forms";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {UpperCasePipe} from "@angular/common";


interface Question {
  id: string;
  runningNumber: number;
  question: string;
  choices: Choice[];
}

interface Choice {
  id: string;
  label: string;
}

@Component({
  selector: 'app-question-card',
  standalone: true,
  imports: [
    NzButtonComponent,
    CountdownTimerComponent,
    NzIconDirective,
    UpperCasePipe
  ],
  templateUrl: './question-card.component.html',
  styleUrl: './question-card.component.scss'
})
export class QuestionCardComponent implements OnInit {
  @Input() question?: Question;
  @Input() form?: FormGroup;
  @Input() hideNext = false;
  @Input() hidePrev = false;
  @Input() currentQuestionIndex = 1;
  @Input() totalQuestions = 0;
  @Input() countdownInSeconds = 0;
  @Output() nextClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() prevClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() selected: EventEmitter<boolean> = new EventEmitter();
  mobileMode = false;

  ngOnInit() {
    console.log(window.innerWidth)
    this.mobileMode = window.innerWidth < 600
    console.log(this.mobileMode);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.mobileMode = window.innerWidth < 600;
  }

  onNext() {
    this.nextClicked.emit(true);
  }

  onPrev() {
    this.prevClicked.emit(true);
  }

  onSelect(value: string) {
    this.form?.patchValue({
      selected: value
    })
    this.selected.emit(true)
  }
}
