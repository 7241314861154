import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NzCommentModule} from "ng-zorro-antd/comment";
import {NzCardModule} from "ng-zorro-antd/card";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzAutosizeDirective, NzInputDirective} from "ng-zorro-antd/input";
import {FormsModule} from "@angular/forms";
import {NzModalModule, NzModalService} from "ng-zorro-antd/modal";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-comment',
  standalone: true,
  imports: [
    NzIconModule,
    NzCommentModule,
    NzCardModule,
    NzAvatarModule,
    NzInputDirective,
    FormsModule,
    NzAutosizeDirective,
    NzModalModule,
    DatePipe
  ],
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.scss'
})
export class CommentComponent {
  @Input() text?: string;
  @Input() author?: string
  @Input() authorShortName?: string
  @Input() dateTime?: string;

  @Output() edited: EventEmitter<string> = new EventEmitter()
  @Output() deleted: EventEmitter<string> = new EventEmitter()
  editMode = false;

  constructor(private nzModalService: NzModalService) {
  }

  onEdit() {
    this.editMode = true;
  }

  onCancel() {
    this.editMode = false;
  }

  onDelete() {
    this.nzModalService.create({
      nzTitle: 'ยืนยันการลบ comment ?',
      nzContent: 'comment ที่ถูกลบไปแล้วจะไม่สามารถนำข้อมูลกลับมาได้อีก',
      nzIconType: 'delete',
      nzOkText: 'Delete',
      nzOkDanger: true,
      nzOnOk: () => {
        this.deleted.emit(this.text);
        this.editMode = false;
      }
    })
  }

  onSave(): void {
    this.edited.emit(this.text);
    this.editMode = false;
  }
}
