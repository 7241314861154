import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private readonly notificationService: NzNotificationService) {}

  success(title: string, message?: string) {
    this.notificationService.success(title, message || '');
  }

  error(title: string, message: string) {
    this.notificationService.error(title, message);
  }

  apiError(e: any) {
    this.notificationService.error('Error', e.error.error.desc);
  }
}
