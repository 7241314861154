import { Component, OnInit } from '@angular/core';
import { NgForOf, TitleCasePipe } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule, NzTableSortFn } from 'ng-zorro-antd/table';
import { RouterLink } from '@angular/router';
import { JobService } from '../../../core/services/job/job.service';
import { NzTagComponent } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { finalize } from 'rxjs';

interface ListOfColumn {
  title: string;
  priority: boolean | number;
  showSort: boolean;
  compare: NzTableSortFn<any> | null;
  align: 'left' | 'center' | 'right' | null;
  width: string | null;
}

interface DataItem {
  id: string;
  jobTitle: string;
  status: string;
  createdDate: string;
  numberOfApplicant: number;
}

@Component({
  selector: 'app-job-list',
  standalone: true,
  imports: [
    NgForOf,
    NzButtonModule,
    NzTableModule,
    RouterLink,
    NzTagComponent,
    TitleCasePipe,
    NzIconModule,
  ],
  templateUrl: './job-list.component.html',
  styleUrl: './job-list.component.scss',
})
export class JobListComponent implements OnInit {
  listOfColumn: ListOfColumn[] = [
    {
      title: 'No',
      compare: null,
      priority: false,
      showSort: false,
      align: null,
      width: '100px',
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.jobTitle.localeCompare(b.jobTitle),
      priority: false,
      showSort: false,
      align: null,
      width: '350px',
    },
    {
      title: 'Applicants',
      compare: null,
      priority: false,
      showSort: false,
      align: 'center',
      width: '150px',
    },
    {
      title: 'Status',
      compare: null,
      priority: false,
      showSort: false,
      align: 'center',
      width: '150px',
    },

    {
      title: '',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: null,
    },
  ];
  listOfData: DataItem[] = [];
  loading = false;

  constructor(
    private jobService: JobService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.fetchJobList();
  }

  onActivate(id: string) {
    this.jobService.jobActivate(id).subscribe({
      next: () => {
        this.notification.success('Success', 'Your Job is activated.');
        this.fetchJobList();
      },
      error: e => {
        this.notification.error(`Error ${e.error.errorMsg}`, '');
      },
    });
  }

  private fetchJobList() {
    this.loading = true;
    this.jobService
      .jobList(1, 100)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.listOfData = response.data.data;
        },
      });
  }
}
