import {Component, OnInit} from '@angular/core';
import {DatePipe, NgForOf, TitleCasePipe} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzTableModule, NzTableSortFn} from "ng-zorro-antd/table";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {NzTagModule} from "ng-zorro-antd/tag";
import {RouterLink} from "@angular/router";
import {NzSelectModule} from "ng-zorro-antd/select";
import {FormsModule} from "@angular/forms";
import {JobService} from "../../../core/services/job/job.service";
import {NzFormModule} from "ng-zorro-antd/form";
import {ExaminationService} from "../../../core/services/examination/examination.service";
import {finalize} from "rxjs";
import {NotificationService} from "../../../shared/services/notification/notification.service";
import {NzDropDownADirective, NzDropDownDirective, NzDropdownMenuComponent} from "ng-zorro-antd/dropdown";
import {NzBadgeComponent} from "ng-zorro-antd/badge";
import {NzMenuDirective} from "ng-zorro-antd/menu";
import {NzDividerComponent} from "ng-zorro-antd/divider";

interface ParentItemData {
  id: string;
  key: string;
  name: string;
  status: string;
  company: string;
  expand: boolean;
}

interface DataItem {
  id: string;
  name: string;
  status: string;
  createdDate: string;
}

interface ListOfColumn {
  title: string;
  priority: boolean | number;
  showSort: boolean;
  compare: NzTableSortFn<any> | null;
  align: 'left' | 'center' | 'right' | null;
  width: string | null;
}

interface Job {
  id: string;
  label: string;
}

@Component({
  selector: 'app-release-list',
  standalone: true,
  imports: [
    NzTableModule,
    NgForOf,
    NzButtonModule,
    NzTagModule,
    RouterLink,
    NzIconDirective,
    NzSelectModule,
    FormsModule,
    NzFormModule,
    TitleCasePipe,
    NzDropdownMenuComponent,
    NzBadgeComponent,
    NzDropDownADirective,
    NzDropDownDirective,
    NzMenuDirective,
    NzDividerComponent,
    DatePipe,
  ],
  templateUrl: './release-list.component.html',
  styleUrl: './release-list.component.scss'
})
export class ReleaseListComponent implements OnInit {
  listOfColumn: ListOfColumn[] = [
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
      priority: false,
      showSort: false,
      align: null,
      width: null
    },
    {
      title: 'Status',
      compare: (a: DataItem, b: DataItem) => a.status.localeCompare(b.status),
      showSort: true,
      priority: false,
      align: 'center',
      width: '20%'
    },
    {
      title: '',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: '250px'
    }
  ];
  listOfData: DataItem[] = [];
  fetchJobLoading = false
  fetchReleaseLoading = false;
  listOfParentData: ParentItemData[] = [];

  releaseLoadingMap: Record<string, {
    loading: boolean,
    fetched: boolean
  }> = {}
  releaseListMap: Record<string, DataItem[]> = {};

  constructor(
    private jobService: JobService,
    private examinationService: ExaminationService,
    private notificationService: NotificationService
  ) {

  }

  onActivateRelease(id: string): void {
    this.examinationService.activateRelease(id).subscribe({
      next: () => {
        this.notificationService.success('Activated', 'activate version success')
        this.fetchListData(false)
      },
      error: e => {
        this.notificationService.error('Failed to activate release', e.error.errorMsg)
      }
    })
  }

  ngOnInit() {
    this.fetchJobLoading = true;
    this.fetchListData(true);
  }

  onJobChange(id: string) {
    this.fetchExaminationList(id)
  }

  onExpand(id: string) {
    console.log(id)
    if (!this.releaseLoadingMap[id].fetched) {
      this.fetchExaminationList(id)
    }
    this.listOfParentData.forEach(i => {
      if (i.id !== id) {
        i.expand = false
      }
    })
  }

  private fetchListData(reset?: boolean) {
    this.jobService.jobList(1, 100).pipe(
      finalize(() => {
        this.fetchJobLoading = false
      })
    ).subscribe({
      next: data => {
        this.listOfParentData = [];
        data.data.forEach(i => {
          this.releaseLoadingMap[i.id] = {
            loading: false,
            fetched: false
          };
          this.releaseListMap[i.id] = [];
          this.listOfParentData.push({
            key: i.id,
            id: i.id,
            name: i.jobTitle,
            status: i.status,
            company: i.companyName,
            expand: false,
          })
        })

        // this.listOfJob = data.data.map(item => {
        //   return {
        //     id: item.id,
        //     label: item.jobTitle
        //   }
        // })
        // if (this.listOfJob.length) {
        //   if (reset) {
        //     this.jobSelected = this.listOfJob[0].id
        //     this.fetchExaminationList(this.jobSelected)
        //   } else if (this.jobSelected) {
        //     this.fetchExaminationList(this.jobSelected)
        //   }
        //
        // }
      },
      error: e => {
        this.notificationService.apiError(e)
      }
    })
  }

  private fetchExaminationList(id: string) {
    this.releaseLoadingMap[id].loading = true;
    this.examinationService.getReleaseListByJobId(id).pipe(
      finalize(() => {
        this.fetchReleaseLoading = false;
      })
    ).subscribe({
      next: data => {
        this.releaseListMap[id] = data.jobAssessVerList.map(i => {
          return {
            id: i.id,
            name: i.name,
            status: i.status,
            createdDate: i.createdDate
          }
        });
        this.releaseLoadingMap[id] = {
          loading: false,
          fetched: true
        };
      },
      error: e => {
        this.notificationService.apiError(e)
      }
    })
  }
}
