import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {faAngleDoubleRight, faBook, faFlaskVial, faLocationDot, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import {RouteHistoryService} from "./shared/services/route-history/route-history.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FontAwesomeModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'ai-recruit';

  constructor(private library: FaIconLibrary, private routeHistoryService: RouteHistoryService) {
    this.library.addIcons(faLocationDot, faPhoneSquare, faBook, faFlaskVial, faAngleDoubleRight)
  }
}
