/* @vite-ignore */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { finalize, interval, map, Observable, of, take } from 'rxjs';
import { AsyncPipe, DatePipe, DecimalPipe, Location, NgIf } from '@angular/common';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CandidateService } from '../../core/services/candidate/candidate.service';
import { ActivatedRoute } from '@angular/router';
import { convertBase64ToImage } from '../../helpers/base64';
import { NzTypographyComponent } from 'ng-zorro-antd/typography';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzCardComponent } from 'ng-zorro-antd/card';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { FormsModule } from '@angular/forms';
import { InterviewService } from '../../core/services/interview/interview.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { CommentComponent } from './comment/comment.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { getCurrentDomainWithoutSubPath } from '../../helpers/url';
import { Clipboard } from '@angular/cdk/clipboard';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

interface Candidate {
  name: string;
  mail: string;
  id: string;
  status: string;
  mobile: string;
  skillMatch: number;
  expMatch: number;
  educationMatch: number;
  softSkillMatch: number;
  jobTitleMatch: number;
  overallMatch: number;
  conclusion: string;
  jobTitle: string;
  image: string;
  examScore?: number | null;
  interviewerComment: Comment[];
  startAssessDateTime: string;
  totalScore: number;
  createdDate: string;
}

interface Comment {
  id: string;
  comment: string;
  createdDate: string;
  lastModifiedDate?: any;
}

@Component({
  selector: 'app-candidate',
  standalone: true,
  imports: [
    PdfViewerModule,
    NzModalModule,
    NzRadioModule,
    NzSelectModule,
    NzDrawerModule,
    NzFormModule,
    NzCommentModule,
    NzIconModule,
    NzAvatarModule,
    NzProgressModule,
    AsyncPipe,
    NzStatisticModule,
    DecimalPipe,
    NzButtonModule,
    NgIf,
    NzTypographyComponent,
    NzInputDirective,
    NzCardComponent,
    FormsModule,
    DatePipe,
    CommentComponent,
    NzSpinComponent,
    FaIconComponent,
  ],
  templateUrl: './candidate.component.html',
  styleUrl: './candidate.component.scss',
})
export class CandidateComponent implements OnInit, AfterViewInit {
  pdfSrc: string | Uint8Array | null;
  candidate?: Candidate;
  score: Observable<number>;
  chart: any;
  toolsVisible = false;
  comment?: string;
  retestVisible = false;
  retestLoading = false;
  guidelineVisible = false;
  guidelineLang = 'TH';
  guideline: string = '';
  guidelineLoading = false;
  retestLink = '';
  id: string = '';
  changeStatusModalVisible = false;
  changeStatusLoading = false;
  candidateStatus = '';
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

  constructor(
    private location: Location,
    private candidateService: CandidateService,
    private route: ActivatedRoute,
    private interviewService: InterviewService,
    private notification: NotificationService,
    private clipboard: Clipboard
  ) {
    this.pdfSrc = '';
    this.score = of(0);
  }

  ngAfterViewInit() {}

  onUpdateChangeStatus() {
    this.changeStatusLoading = true;
    this.interviewService
      .changeStatus(this.id, this.candidateStatus)
      .pipe(
        finalize(() => {
          this.changeStatusLoading = false;
        })
      )
      .subscribe({
        next: () => {
          this.notification.success('Update candidate status success');
          this.changeStatusModalVisible = false;
        },
        error: e => {
          this.notification.apiError(e);
        },
      });
  }

  close(): void {
    this.toolsVisible = false;
  }

  onCloseChangeStatus() {
    this.changeStatusModalVisible = false;
  }

  onChangeCandidateStatus() {
    this.changeStatusModalVisible = true;
  }

  onCloseGuideLine() {
    this.guidelineVisible = false;
  }

  onShowGuideLine(): void {
    this.guidelineVisible = true;
  }

  onGetGuideLine(): void {
    this.guidelineLoading = true;
    this.interviewService
      .getInterviewQuestion(this.id, this.guidelineLang)
      .pipe(
        finalize(() => {
          this.guidelineLoading = false;
        })
      )
      .subscribe({
        next: res => {
          this.guideline = res.data.guideLine;
        },
        error: e => {
          this.notification.apiError(e);
        },
      });
  }

  onBack() {
    this.location.back();
  }

  onOpenTools() {
    this.toolsVisible = true;
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getCandidateDetail();
    this.getResumePdf();
  }

  onCopy() {
    this.clipboard.copy(this.getRetestLink());
    this.notification.success('Copied', '');
  }

  onComment(): void {
    const id = this.route.snapshot.params['id'];
    if (this.comment) {
      this.interviewService.comment(id, this.comment).subscribe({
        next: () => {
          this.notification.success('Comment Added');
          this.getCandidateDetail(true);
          this.comment = '';
        },
        error: e => {
          this.notification.apiError(e);
        },
      });
    }
  }

  onCloseRetest() {
    this.retestVisible = false;
  }

  onGenerateRetestLink() {
    this.retestVisible = true;
    this.retestLoading = true;
    this.retestLink = this.getRetestLink();
  }

  scrollToBottom(): void {
    console.log('sss');
    const element = this.scrollContainer.nativeElement;
    element.scrollTop = element.scrollHeight;
  }

  onCommentDelete(id: string) {
    this.interviewService
      .deleteComment({
        applicantId: this.id,
        id: id,
      })
      .subscribe({
        next: () => {
          this.getCandidateDetail(true);
          this.notification.success('Comment deleted');
        },
        error: e => {
          this.notification.apiError(e);
        },
      });
  }

  onCommentUpdate(id: string, text: string) {
    this.interviewService
      .updateComment({
        applicantId: this.id,
        id: id,
        comment: text,
      })
      .subscribe({
        next: () => {
          this.getCandidateDetail(true);
          this.notification.success('Comment Updated');
        },
        error: e => {
          this.notification.apiError(e);
        },
      });
  }

  private getRetestLink(): string {
    const id = this.route.snapshot.params['id'];
    const domain = getCurrentDomainWithoutSubPath();
    return domain + `/test?f=1&id=${id}`;
  }

  private getCandidateDetail(scrollBottom: boolean = false) {
    this.candidateService.candidateProfile(this.route.snapshot.params['id']).subscribe({
      next: res => {
        const data = res.data;
        this.candidate = {
          id: data.applicantId,
          name: data.fullName,
          mobile: data.phoneNo,
          mail: data.email,
          status: data.status,
          educationMatch: data.educationMatch,
          expMatch: data.expMatch,
          jobTitleMatch: data.jobTitleMatch,
          overallMatch: data.overallMatch,
          skillMatch: data.skillMatch,
          softSkillMatch: data.softSkillMatch,
          conclusion: data.conclusion,
          jobTitle: data.jobTitle,
          examScore: data.examScore,
          image: convertBase64ToImage(data.imageBase64),
          interviewerComment: data.interviewerComment,
          startAssessDateTime: data.startAssessDateTime,
          totalScore: data.totalScore,
          createdDate: data.createdDate,
        };
        this.candidateStatus = data.status;
        const source = interval(10);
        this.score = source.pipe(
          take(typeof data.examScore === 'number' ? data.totalScore : data.overallMatch),
          map(value => value + 1)
        );
        if (scrollBottom) {
          setTimeout(() => {
            this.scrollToBottom();
          });
        }
      },
      error: e => {
        this.notification.apiError(e);
      },
    });
  }

  private getResumePdf() {
    this.candidateService.getResume(this.route.snapshot.params['id']).subscribe({
      next: blob => {
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result as ArrayBuffer;
          this.pdfSrc = new Uint8Array(arrayBuffer);
        };
        reader.readAsArrayBuffer(blob);
      },
    });
  }
}
