import { Component, OnInit } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CompanyService } from '../../../core/services/company/company.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { JobService } from '../../../core/services/job/job.service';
import { concatMap } from 'rxjs';
import { getCurrentDomainWithoutSubPath } from '../../../helpers/url';
import { Router, RouterLink } from '@angular/router';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { validateForm } from '../../../helpers/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCardComponent } from 'ng-zorro-antd/card';

interface Company {
  id: string;
  name: string;
}

@Component({
  selector: 'app-create-job',
  standalone: true,
  imports: [
    NgForOf,
    NzInputModule,
    NzFormModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormsModule,
    NzButtonModule,
    NgIf,
    NzSpinComponent,
    NzIconModule,
    RouterLink,
    NzRadioModule,
    NzCardComponent,
  ],
  templateUrl: './create-job.component.html',
  styleUrl: './create-job.component.scss',
})
export class CreateJobComponent implements OnInit {
  form: FormGroup;
  companies: Company[] = [];
  id?: string;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private jobService: JobService,
    private notification: NotificationService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      company: ['', Validators.required],
      prompt: [''],
      language: ['TH'],
      res: this.formBuilder.array([this.formBuilder.control('', Validators.required)]),
      qualifications: this.formBuilder.array([this.formBuilder.control('', Validators.required)]),
    });
  }

  get resArray(): FormArray {
    return this.form.get('res') as FormArray;
  }

  get quaArray(): FormArray {
    return this.form.get('qualifications') as FormArray;
  }

  onBack() {
    this.router.navigateByUrl('/career');
  }

  ngOnInit() {
    this.companyService.companyList().subscribe({
      next: list => {
        this.companies = list.data.map(i => {
          return {
            id: i.companyId,
            name: i.companyName,
          };
        });
        if (this.companies.length) {
          this.form.get('company')?.patchValue(this.companies[0].id);
        }
      },
    });
  }

  onCreate() {
    if (this.form.valid) {
      console.log(this.form);
      this.jobService
        .createJob({
          jobTitle: this.form.value.title,
          companyId: this.form.value.company,
          respEn: this.form.value.qualifications,
          jobQEn: this.form.value.res,
        })
        .subscribe({
          next: res => {
            this.router.navigateByUrl(`/edit-job/${res.data.id}`);
            this.notification.success('Job Created successfully.');
          },
          error: e => {
            this.notification.apiError(e);
          },
        });
    } else {
      validateForm(this.form);
    }
  }

  disableAll() {
    Object.values(this.form.controls).forEach(control => {
      if (control instanceof FormArray) {
        Object.values(control.controls).forEach(i => {
          i.disable();
        });
      } else if (control instanceof FormControl) {
        control.disable();
      }
    });
  }

  unDisableAll() {
    Object.values(this.form.controls).forEach(control => {
      if (control instanceof FormArray) {
        Object.values(control.controls).forEach(i => {
          i.enable();
        });
      } else if (control instanceof FormControl) {
        control.enable();
      }
    });
  }

  onGenerate() {
    if (this.form.get('title')?.valid && this.form.get('prompt')?.valid) {
      this.loading = true;
      this.disableAll();
      console.log('valid');
      this.jobService
        .createJob({
          jobTitle: this.form.value.title,
          companyId: this.form.value.company,
          respEn: [],
          jobQEn: [],
        })
        .pipe(
          concatMap(job => {
            console.log(job, '@@@');
            return this.jobService.generateDescByAI({
              jobDescId: job.data.id,
              jobTitle: this.form.value.title,
              prompt: this.form.value.prompt,
              companyId: this.form.value.company,
              language: this.form.value.language,
            });
          })
        )
        .subscribe({
          next: res => {
            this.id = res.data.id;
            this.loading = false;
            this.router.navigateByUrl(`/career/edit/${res.data.id}`);
            this.notification.success(
              `Create job success`,
              `${this.form.value.title} has created successfully!`
            );
          },
          error: e => {
            this.notification.error('Error', e.error.errorMsg);
          },
        });
    } else {
      ['title', 'prompt'].forEach(i => {
        const control = this.form.get(i);
        if (control && control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  onResRemoveIndex(index: number) {
    this.resArray.removeAt(index);
  }

  onQuaRemoveIndex(index: number) {
    this.quaArray.removeAt(index);
  }

  onSave(): void {
    if (this.id) {
      this.disableAll();
      this.loading = true;
      this.jobService
        .updateJob({
          companyId: this.form.value.company,
          id: this.id,
          jobQ: this.form.value.qualifications,
          jobTitle: this.form.value.title,
          resp: this.form.value.res,
        })
        .subscribe({
          next: data => {
            this.notification.success('Success', 'Your Data is saved successfully.');
            this.unDisableAll();
            this.loading = false;
          },
          complete: () => {
            this.unDisableAll();
            this.loading = false;
          },
        });
    }
  }

  onPreview() {
    const domain = getCurrentDomainWithoutSubPath();
    window.open(domain + '/register/' + this.id, '_blank');
  }

  onResAdd() {
    this.resArray.push(this.formBuilder.control('', Validators.required));
  }

  onQualificationAdd() {
    this.quaArray.push(this.formBuilder.control('', Validators.required));
  }

  private updateFormByAIData(res: string[], qua: string[]) {
    this.resArray.clear();
    this.quaArray.clear();

    res.forEach(i => {
      this.resArray.push(this.formBuilder.control(i, Validators.required));
    });

    qua.forEach(i => {
      this.quaArray.push(this.formBuilder.control(i, Validators.required));
    });

    console.log(this.form.value);
  }
}
