import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CompanyListResponse, CreateCompanyRequest} from "./company.model";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) {
  }

  companyList(): Observable<CompanyListResponse> {
    const url = environment.apiUrl + '/companies'
    return this.http.get<CompanyListResponse>(url)
  }

  createCompany(payload: CreateCompanyRequest): Observable<void> {
    const url = environment.apiUrl + '/companies'
    return this.http.post<void>(url, payload)
  }
}
