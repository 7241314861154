import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {
  CreateJobPayload,
  CreateJobResponse,
  GenerateDescByAIPayload,
  GenerateDescByAIResponse,
  getJobDetailForRegisterPageResponse,
  JobListResponse,
  UpdateJobPayload
} from "./job.model";


@Injectable({
  providedIn: 'root'
})
export class JobService {
  constructor(private http: HttpClient) {
  }

  getJobForPreview(id: string): Observable<getJobDetailForRegisterPageResponse> {
    const url = `${environment.apiUrl}/jobDescs/${id}/preview`;
    return this.http.get<getJobDetailForRegisterPageResponse>(url)
  }

  jobActivate(id: string): Observable<string> {
    const url = `${environment.apiUrl}/jobDescs/${id}/activate`;
    return this.http.patch(url, {}, {responseType: 'text'})
  }


  getJobDetail(id: string): Observable<getJobDetailForRegisterPageResponse> {
    const url = `${environment.apiUrl}/jobDescs/${id}`;
    return this.http.get<getJobDetailForRegisterPageResponse>(url)
  }

  jobList(page: number, size: number): Observable<JobListResponse> {
    const url = `${environment.apiUrl}/jobDescs/page`;
    return this.http.get<JobListResponse>(url, {
      params: {
        page: page,
        size: size
      }
    })
  }

  createJob(payload: CreateJobPayload): Observable<CreateJobResponse> {
    const url = `${environment.apiUrl}/jobDescs`;
    return this.http.post<any>(url, payload)
  }

  updateJob(payload: UpdateJobPayload): Observable<void> {
    const url = `${environment.apiUrl}/jobDescs/${payload.id}`;
    return this.http.put<void>(url, {
      jobTitle: payload.jobTitle,
      resp: payload.resp,
      jobQ: payload.jobQ,
      companyId: payload.companyId,
    })

  }

  generateDescByAI(payload: GenerateDescByAIPayload): Observable<GenerateDescByAIResponse> {
    const url = `${environment.apiUrl}/jobDescs/generate`;
    return this.http.post<GenerateDescByAIResponse>(url, payload);

  }
}
