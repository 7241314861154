import {Component, OnInit} from '@angular/core';
import {NzTableModule, NzTableSortFn} from "ng-zorro-antd/table";
import {Location, NgForOf} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzTagModule} from "ng-zorro-antd/tag";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {RankingService} from "../../core/services/ranking/ranking.service";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {NotificationService} from "../../shared/services/notification/notification.service";

const CANDIDATE_STATUS: Record<string, string> = {
  READY_FOR_INTERVIEW: "Awaiting Interview",
  PENDING_DECISION: "Interviews Finished",
  NOT_PASS_INTERVIEW: "Not Selected",
  PASS_INTERVIEW: "Onboarded",
}

interface DataItem {
  id: string;
  name: string;
  examsScore: number;
  resumeScore: number;
  totalScore: number;
  status: string;
  statusDisplay: string;
}

interface ListOfColumn {
  title: string;
  priority: boolean | number;
  showSort: boolean;
  compare: NzTableSortFn<any> | null;
  align: 'left' | 'center' | 'right' | null;
  width: string | null;
}

@Component({
  selector: 'app-ranking',
  standalone: true,
  imports: [
    NzTableModule,
    NgForOf,
    NzButtonModule,
    NzTagModule,
    RouterLink,
    NzIconDirective
  ],
  templateUrl: './ranking.component.html',
  styleUrl: './ranking.component.scss'
})
export class RankingComponent implements OnInit {
  listOfColumn: ListOfColumn[] = [
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
      priority: false,
      showSort: false,
      align: null,
      width: '250px'
    },
    {
      title: 'Status',
      compare: (a: DataItem, b: DataItem) => a.status.localeCompare(b.status),
      showSort: true,
      priority: false,
      align: 'center',
      width: '150px'
    },
    {
      title: 'Exams Score',
      compare: (a: DataItem, b: DataItem) => a.examsScore - b.examsScore,
      showSort: true,
      priority: false,
      align: 'center',
      width: '150px'
    },
    {
      title: 'Resume Score',
      compare: (a: DataItem, b: DataItem) => a.resumeScore - b.resumeScore,
      showSort: true,
      priority: false,
      align: 'center',
      width: '150px'
    },
    {
      title: 'Total Score',
      compare: (a: DataItem, b: DataItem) => a.totalScore - b.totalScore,
      showSort: true,
      priority: false,
      align: 'center',
      width: '150px'
    },
    {
      title: '',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: '250px'
    }
  ];
  listOfData: DataItem[] = [];
  loading = false;

  constructor(
    private rankingService: RankingService,
    private route: ActivatedRoute,
    private location: Location,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {

    const id = this.route.snapshot.params['id'];
    this.loading = true;
    this.rankingService.getRankingById(id, 1, 100).subscribe({
      next: data => {
        this.listOfData = data.data.map(a => {
          return {
            id: a.applicantId,
            name: a.fullName,
            examsScore: a.examScore,
            resumeScore: a.overallMatch,
            totalScore: a.totalScore,
            status: a.applicantStatus,
            statusDisplay: CANDIDATE_STATUS[a.applicantStatus]
          }
        }).sort((a, b) => b.totalScore - a.totalScore);
        this.loading = false
      },
      error: e => {
        this.notificationService.apiError(e)
      }
    })
  }

  onBack() {
    this.location.back()
  }
}
