<div class="job-list-page">
  <div class="job-list-page__content">
    <div class="d-flex d-jus-between">
      <div class="app-text-header">Career Listings
      </div>
      <div>
        <button class="btn-system" nz-button nzSize="large" routerLink="create"><span nz-icon nzTheme="outline"
                                                                                      nzType="plus"></span>New
        </button>
      </div>
    </div>
    <nz-table #sortTable [nzData]="listOfData" [nzLoading]="loading" nzTableLayout="fixed">
      <thead>
      <tr>
        <th *ngFor="let column of listOfColumn" [nzAlign]="column.align" [nzShowSort]="column?.showSort"
            [nzSortFn]="column.compare"
            [nzWidth]="column.width">
          {{ column.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of sortTable.data; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ data.jobTitle }}</td>
        <td nzAlign="center">{{ data.numberOfApplicant }}</td>
        <td nzAlign="center">
          @switch (data.status) {
            @case ('ACTIVE') {
              <nz-tag class="btn-system">{{ data.status | titlecase }}</nz-tag>
            }
            @default {
              <nz-tag class="btn-system--normal">{{ data.status  | titlecase }}</nz-tag>
            }
          }
        </td>
        <td nzAlign="center">
          <div class="d-flex d-jus-center" style="gap: 24px">
            <button [routerLink]="['/','ranking',data.id]" nz-button><span nz-icon nzTheme="outline"
                                                                           nzType="ordered-list"></span>Ranking
            </button>
            <button [routerLink]="['edit',data.id]" nz-button><span nz-icon nzTheme="outline"
                                                                    nzType="file-text"></span>
              Detail
            </button>
            <button (click)="onActivate(data.id)" [disabled]="data.status === 'ACTIVE'" nz-button><span
              nz-icon
              nzTheme="outline"
              nzType="file-done"></span>Activate
            </button>
            <button disabled nz-button><span nz-icon nzTheme="outline" nzType="delete"></span>Delete</button>
          </div>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
