<div class="company-page">
  <div class="company-page__content">
    <div class="d-flex d-jus-between">
      <div class="app-text-header">Company</div>
      <div>
        <button (click)="onCreate()" class="btn-system" nz-button nzSize="large"><span nz-icon nzTheme="outline"
                                                                                       nzType="plus"></span>Create
        </button>
      </div>
    </div>
    <nz-table #sortTable [nzData]="listOfData" [nzLoading]="loading" nzTableLayout="fixed">
      <thead>
      <tr>
        <th *ngFor="let column of listOfColumn" [nzAlign]="column.align" [nzShowSort]="column?.showSort"
            [nzSortFn]="column.compare"
            [nzWidth]="column.width">
          {{ column.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of sortTable.data;let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ data.companyName }}</td>
        <td>
          <div class="d-flex" style="gap: 24px">
            <button [routerLink]="['/','company',data.companyId]" disabled nz-button><span nz-icon nzTheme="outline"
                                                                                           nzType="file-text"></span>Detail
            </button>
            <button disabled nz-button><span nz-icon nzTheme="outline" nzType="delete"></span>Delete</button>
          </div>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
<nz-modal
  (nzOnCancel)="handleCancel()"
  [(nzVisible)]="isVisible"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzTitle]="modalTitle"
>
  <ng-template #modalTitle>Create Company</ng-template>

  <ng-template #modalContent>
    <form [formGroup]="form" nz-form>
      <nz-form-item>
        <nz-form-label nzSpan="6">Company Name</nz-form-label>
        <nz-form-control nzErrorTip="Please input your company." nzSpan="18">
          <input formControlName="name" nz-input/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSpan="6">Location</nz-form-label>
        <nz-form-control nzErrorTip="Please input your location." nzSpan="18">
          <input formControlName="location" nz-input/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSpan="6">Phone Number</nz-form-label>
        <nz-form-control nzErrorTip="Please input your phone number." nzSpan="18">
          <input formControlName="phoneNo" nz-input/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSpan="6">Description</nz-form-label>
        <nz-form-control nzErrorTip="Please input your company description." nzSpan="18">
          <textarea formControlName="compDesc" maxlength="600" nz-input rows="2"> </textarea>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button (click)="handleCancel()" nz-button nzType="default">Cancel</button>
    <button (click)="onSubmit()" [nzLoading]="isConfirmLoading" class="btn-system" nz-button nzType="primary">Create
    </button>
  </ng-template>
</nz-modal>
