import {Component, OnInit} from '@angular/core';
import {NzButtonModule} from "ng-zorro-antd/button";
import {JsonPipe, NgForOf, UpperCasePipe} from "@angular/common";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzFormModule} from "ng-zorro-antd/form";
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzTypographyComponent, NzTypographyModule} from "ng-zorro-antd/typography";
import {NzOptionComponent, NzSelectModule} from "ng-zorro-antd/select";
import {ExaminationService} from "../../../core/services/examination/examination.service";
import {concatMap, finalize} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CreateJobAssessPayload} from "../../../core/services/examination/examination.model";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {validateForm} from "../../../helpers/form";
import {NzCardComponent} from "ng-zorro-antd/card";
import {NotificationService} from "../../../shared/services/notification/notification.service";
import {NzRadioComponent, NzRadioGroupComponent} from "ng-zorro-antd/radio";
import {RouteHistoryService} from "../../../shared/services/route-history/route-history.service";

@Component({
  selector: 'app-create-examination',
  standalone: true,
  imports: [
    NzButtonModule,
    NzIconModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    NzTypographyComponent,
    NzTypographyModule,
    NgForOf,
    JsonPipe,
    UpperCasePipe,
    NzOptionComponent,
    NzSelectModule,
    NzSpinModule,
    NzCardComponent,
    NzIconModule,
    NzRadioComponent,
    NzRadioGroupComponent
  ],
  templateUrl: './create-examination.component.html',
  styleUrl: './create-examination.component.scss'
})
export class CreateExaminationComponent implements OnInit {
  examinationForm: FormGroup;
  loading = false;
  jobId = ''
  releaseId = ''

  constructor(
    private fb: FormBuilder,
    private examinationService: ExaminationService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private routeHistory: RouteHistoryService
  ) {
    this.examinationForm = fb.group({
      name: ['', Validators.required],
      time: [''],
      prompt: [''],
      language: ['TH'],
      questionsForGen: [10],
      exams: this.fb.array([])
    })
  }

  get examinationArray(): FormArray {
    return this.examinationForm.get('exams') as FormArray;
  }

  addQuestion(
    question: string,
    correctAnswer: string,
    choicesAnswer: string[]
  ) {
    this.examinationArray.push(this.fb.group({
      question: [question, Validators.required],
      correctAnswer: [correctAnswer, Validators.required],
      choicesAnswer: this.fb.array(this.addDefaultChoice(choicesAnswer)),
    }))
  }

  addDefaultChoice(choice?: string[]): FormGroup[] {
    const fs: FormGroup[] = []
    const defaultChoice = ['a', 'b', 'c', 'd'];
    if (choice) {
      defaultChoice.forEach((c, index) => {
        fs.push(this.fb.group({
          id: [c, Validators.required],
          answer: [choice[index], Validators.required]
        }))
      })
    } else {
      defaultChoice.forEach((choice) => {
        fs.push(this.fb.group({
          id: [choice, Validators.required],
          answer: ['', Validators.required]
        }))
      })
    }

    return fs;
  }

  onDelete(index: number) {
    this.examinationArray.removeAt(index)
  }

  ngOnInit() {
    // this.onAddQuestion()
    const params = this.route.snapshot.params;
    this.releaseId = params['releaseId'];
    this.jobId = params['jobId']
  }

  onBack() {
    // this.routeHistory.back()
    this.router.navigateByUrl(`/examination/release/${this.releaseId}/${this.jobId}`)

  }

  onGenerate() {
    this.loading = true;
    this.examinationService.createJobAssess({
      jobAssessName: this.examinationForm.value.name,
      jobDescId: this.route.snapshot.params['id'],
      questions: [],
      totalTime: this.examinationForm.value.time
    }).pipe(
      concatMap(data => {
        return this.examinationService.generateExamination({
          jobAssessId: data.id,
          noQuestion: this.examinationForm.value.questionsForGen,
          prompt: this.examinationForm.value.prompt,
          language: this.examinationForm.value.language
        })
      }),
      finalize(() => {
        this.loading = false
      })
    ).subscribe({
      next: examination => {
        this.loading = false;
        this.routeHistory.removeOne();
        this.router.navigateByUrl(`/examination/edit/${examination.id}`);
        this.notificationService.success('Create Examination Success', '')
      },
      error: e => {
        this.notificationService.apiError(e)
      }
    })
  }

  onAddQuestion() {
    this.examinationArray.push(this.fb.group({
      question: ['', Validators.required],
      correctAnswer: ['', Validators.required],
      choicesAnswer: this.fb.array(this.addDefaultChoice()),
    }))
  }

  onCreate() {
    if (this.examinationForm.valid) {
      this.loading = true;
      const createPayload: CreateJobAssessPayload = {
        jobDescId: this.route.snapshot.params['id'],
        jobAssessName: this.examinationForm.value.name,
        totalTime: this.examinationForm.value.time,
        questions: this.examinationForm.value.exams.map((i: any) => {
          return {
            question: i.question,
            correctAnswer: i.correctAnswer,
            choice: i.choicesAnswer.reduce((acc: any, curr: any) => {
              acc[curr.id] = curr.answer;
              return acc;
            }, {} as Record<string, string>)
          }
        })
      }

      this.examinationService.createJobAssess(createPayload).pipe(
        finalize(() => {
          this.loading = false
        })
      ).subscribe({
        next: examination => {
          this.routeHistory.removeOne();
          this.router.navigateByUrl(`/examination/edit/${examination.id}`);
          this.notificationService.success('Create Examination Success', '')
        },
        error: e => {
          this.notificationService.apiError(e)
        }
      })
    } else {
      validateForm(this.examinationForm)
    }

  }


  private getNextChar(input: string): string {
    if (input.length !== 1 || !/^[a-z]$/.test(input)) {
      throw new Error('Input must be a single lowercase letter from a to z');
    }
    const charCode = input.charCodeAt(0);
    return (charCode === 122) ? 'a' : String.fromCharCode(charCode + 1);
  }
}
