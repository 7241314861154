<div class="comment">
  <div class="comment-menu" style="gap: 16px">
    @if (!editMode && authorShortName !== 'AI') {
      <div (click)="onEdit()" class="comment-menu__item">
        <span nz-icon nzType="edit"></span>
      </div>
      <div class="comment-menu__item" (click)="onDelete()">
        <span nz-icon nzType="delete"></span>
      </div>
    } @else if (editMode && authorShortName !== 'AI') {
      <div (click)="onSave()" class="comment-menu__item"><span nz-icon nzType="save" nzTheme="outline"></span></div>
      <div (click)="onCancel()" class="comment-menu__item"><span nz-icon nzType="close" nzTheme="outline"></span>
      </div>
    }
  </div>
  <nz-comment [nzAuthor]="author" [nzDatetime]="( dateTime | date:'short') || ''">
    <nz-avatar
      [class.ai-bg]="authorShortName === 'AI'"
      [class.iv-bg]="authorShortName !== 'AI'"
      [nzText]="authorShortName"
      nz-comment-avatar
    ></nz-avatar>
    <nz-comment-content>
      @if (editMode) {
        <textarea [nzAutosize]="true" nz-input [(ngModel)]="text"></textarea>
      } @else {
        <p>{{ text }}
        </p>
      }
    </nz-comment-content>
  </nz-comment>
</div>

<ng-template #actionEdit>
  <span nz-icon nzType="edit"></span>
</ng-template>
<ng-template #actionDelete>
  <span nz-icon nzType="delete"></span>
</ng-template>
<ng-template #extraTemplate>
  <a>More</a>
</ng-template>
