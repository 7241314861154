import {Component, OnInit} from '@angular/core';
import {Location, NgForOf} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzTagModule} from "ng-zorro-antd/tag";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {NzFormModule} from "ng-zorro-antd/form";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NzInputModule} from "ng-zorro-antd/input";
import {ExaminationService} from "../../../core/services/examination/examination.service";
import {NotificationService} from "../../../shared/services/notification/notification.service";
import {RouteHistoryService} from "../../../shared/services/route-history/route-history.service";

interface DataItem {
  id: string;
  name: string;
  totalNoQuestion: number;
  totalTime: string;
  status: string
}


@Component({
  selector: 'app-create-release',
  standalone: true,
  imports: [
    NzTableModule,
    NgForOf,
    NzButtonModule,
    NzTagModule,
    RouterLink,
    NzIconDirective,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
  ],
  templateUrl: './create-release.component.html',
  styleUrl: './create-release.component.scss'
})
export class CreateReleaseComponent implements OnInit {
  setOfCheckedId = new Set<string>();
  checked = false;
  indeterminate = false;
  listOfData: DataItem[] = [];
  loading = false;
  form: FormGroup;
  jobId: string = '';

  constructor(
    private location: Location,
    private examinationService: ExaminationService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private routeHistoryService: RouteHistoryService,
    private fb: FormBuilder) {
    this.form = this.fb.group({
      name: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.jobId = this.route.snapshot.params['id'];
    this.examinationService.getAllJobAssessByJobId(this.jobId).subscribe({
      next: data => {
        this.listOfData = data.jobAssess;
      }
    })
  }

  onBack() {
    // console.log(this.routeHistoryService.getHistory())
    // this.routeHistoryService.back();
    this.router.navigateByUrl('/examination')

  }

  onSubmit() {
    if (this.form.valid) {
      this.examinationService.createRelease({
        jobDescId: this.route.snapshot.params['id'],
        name: this.form.value.name,
        jobAssessIds: Array.from(this.setOfCheckedId)
      }).subscribe({
        next: data => {
          this.notification.success(`Create ${this.form.value.name} Success`, '')
          this.routeHistoryService.remove(this.routeHistoryService.getHistory().length);
          this.router.navigateByUrl(`/examination/release/${data.id}/${this.route.snapshot.params['id']}`)
        },
        error: e => {
          this.notification.apiError(e)
        }
      })
    } else {
      this.form.get('name')?.markAsDirty();
      this.form.get('name')?.updateValueAndValidity();
    }
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfData
      .forEach(({id}) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }


  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfData;
    this.checked = listOfEnabledData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({id}) => this.setOfCheckedId.has(id)) && !this.checked;
  }
}
