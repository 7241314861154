<div class="result">
  @switch (status) {
    @case ('CANDIDATE_SUBMITTED') {
      <nz-result
        nzStatus="success"
        nzSubTitle="We're Excited to Review Your Application"
        nzTitle="Thank You for Submitting Your Resume!"
      >
      </nz-result>
    }
    @case ('EXAMS_SUBMITTED') {
      <nz-result
        nzStatus="success"
        nzTitle="Thank you for completing the exam!"
        nzSubTitle="Your submission has been successfully received. We will carefully review your work and get back to you with the next steps shortly."
      >
      </nz-result>
    }
    @case ('EXAMS_EXPIRED') {
      <nz-result nzStatus="warning" nzTitle="Exam Link Expired"
                 nzSubTitle="If you believe this is an error or if you need assistance, please don’t hesitate to contact us at [ai-recruit@gmail.com].">
        <div nz-result-extra>
          {{ 'We hope this message finds you well. Unfortunately, the link to your exam has expired and is no longer accessible.' }}
        </div>
      </nz-result>
    }
    @default {
      <nz-result nzStatus="404" nzTitle="404" nzSubTitle="Sorry, the page you visited does not exist.">

      </nz-result>
    }
  }
</div>
