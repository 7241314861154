import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {NzMenuModule} from "ng-zorro-antd/menu";
import {RouterLink, RouterOutlet} from "@angular/router";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzButtonComponent} from "ng-zorro-antd/button";
import {Location, NgIf} from "@angular/common";
import {NzBackTopModule} from "ng-zorro-antd/back-top";
import { AuthService } from '../../../core/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    NzMenuModule,
    RouterOutlet,
    RouterLink,
    NzIconModule,
    NzButtonComponent,
    NzBackTopModule,
    NgIf,
  ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent implements AfterViewInit {
  isVisible = false;
  @ViewChild('content', {static: false}) contentElement!: ElementRef;

  constructor(
    private location: Location,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngAfterViewInit(): void {
    this.contentElement.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(): void {
    const threshold = 300;
    const currentScrollPosition = this.contentElement.nativeElement.scrollTop;

    this.isVisible = currentScrollPosition > threshold;
  }


  onGoToTop(): void {
    if (this.contentElement) {
      this.smoothScrollToTop(this.contentElement.nativeElement);
    }
  }

  smoothScrollToTop(element: HTMLElement) {
    const scrollDuration = 400; // Duration in ms
    const scrollStep = -element.scrollTop / (scrollDuration / 15);  // Step based on duration

    const scrollInterval = setInterval(() => {
      if (element.scrollTop !== 0) {
        element.scrollTop += scrollStep;  // Adjust scrollTop in steps
      } else {
        clearInterval(scrollInterval);  // Stop when scroll reaches the top
      }
    }, 15);  // Time interval for smooth animation
  }

  onSignOut(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
