import {Component, OnInit} from '@angular/core';
import {NzResultModule} from "ng-zorro-antd/result";
import {NzButtonModule} from "ng-zorro-antd/button";
import {ActivatedRoute} from "@angular/router";

type STATUS = 'CANDIDATE_SUBMITTED' | 'EXAMS_SUBMITTED' | 'EXAMS_EXPIRED';

@Component({
  selector: 'app-result',
  standalone: true,
  imports: [NzResultModule, NzButtonModule],
  templateUrl: './result.component.html',
  styleUrl: './result.component.scss'
})
export class ResultComponent implements OnInit {
  status?: STATUS;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.status = this.route.snapshot.queryParams['status'];
  }
}
