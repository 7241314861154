<div class="register-container">
  <div class="register-card">
    <div class="register-form">
      <h2>Sign Up</h2>

      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>FIRST NAME</label>
          <input nz-input formControlName="firstname" placeholder="First Name" />
          <div *ngIf="registerForm.get('firstname')?.touched && registerForm.get('firstname')?.errors" class="error-message">
            <span *ngIf="registerForm.get('firstname')?.errors?.['required']">First name is required</span>
          </div>
        </div>

        <div class="form-group">
          <label>LAST NAME</label>
          <input nz-input formControlName="lastname" placeholder="Last Name" />
          <div *ngIf="registerForm.get('lastname')?.touched && registerForm.get('lastname')?.errors" class="error-message">
            <span *ngIf="registerForm.get('lastname')?.errors?.['required']">Last name is required</span>
          </div>
        </div>

        <div class="form-group">
          <label>EMAIL</label>
          <input nz-input formControlName="email" placeholder="Email" />
          <div *ngIf="registerForm.get('email')?.touched && registerForm.get('email')?.errors" class="error-message">
            <span *ngIf="registerForm.get('email')?.errors?.['required']">Email is required</span>
            <span *ngIf="registerForm.get('email')?.errors?.['email']">Please enter a valid email</span>
          </div>
        </div>

        <div class="form-group">
          <label>PASSWORD</label>
          <input nz-input type="password" formControlName="password" placeholder="Password" />
          <div *ngIf="registerForm.get('password')?.touched && registerForm.get('password')?.errors" class="error-message">
            <span *ngIf="registerForm.get('password')?.errors?.['required']">Password is required</span>
            <span *ngIf="registerForm.get('password')?.errors?.['minlength']">Password must be at least 6 characters</span>
          </div>
        </div>

        <div class="form-group">
          <label>CONFIRM PASSWORD</label>
          <input nz-input type="password" formControlName="confirmPassword" placeholder="Confirm Password" />
          <div *ngIf="registerForm.get('confirmPassword')?.touched" class="error-message">
            <span *ngIf="registerForm.get('confirmPassword')?.errors?.['required']">Confirm password is required</span>
            <span *ngIf="registerForm.errors?.['passwordMismatch']">Passwords do not match</span>
          </div>
        </div>

        <button
          nz-button
          nzType="primary"
          [disabled]="registerForm.invalid || isLoading"
          [nzLoading]="isLoading"
        >
          Sign Up
        </button>
      </form>

      <div class="social-links">
        <a><i nz-icon nzType="google" nzTheme="outline"></i></a>
      </div>
    </div>

    <div class="welcome-section">
      <h2 class="text-white">Welcome to Register</h2>
      <p>Already have an account?</p>
      <a routerLink="/login" nz-button nzType="default">Sign In</a>
    </div>
  </div>
</div>
