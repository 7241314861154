<div class="release-list">
  <div class="release-list__content">
    <div class="d-flex d-jus-between">
      <div class="app-text-header">Examination Releases</div>
    </div>

    <nz-table #nestedTable [nzData]="listOfParentData" [nzPageSize]="10">
      <thead>
      <tr>
        <th></th>
        <th nzAlign="center">No</th>
        <th>Career Name</th>
        <th nzAlign="center">Career Status</th>
        <th>Company</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
        @for (job of nestedTable.data; track job; let i = $index) {
          <tr>
            <td [(nzExpand)]="job.expand" (click)="onExpand(job.id)"></td>
            <td nzAlign="center">{{ i + 1 }}</td>
            <td>{{ job.name }}</td>
            <td nzAlign="center">{{ job.status }}</td>
            <td>{{ job.company }}</td>
            <td>
              <button class="btn-system" [routerLink]="['release',job.id,'create']" nz-button><span nz-icon
                                                                                                    nzTheme="outline"
                                                                                                    nzType="plus"></span>Create
                New Release
              </button>
            </td>
          </tr>
          <tr [nzExpand]="job.expand">
            <nz-table #innerTable [nzLoading]="releaseLoadingMap[job.id].loading" [nzData]="releaseListMap[job.id]"
                      nzSize="middle" [nzShowPagination]="false">
              <thead>
              <tr>
                <th width="500px">Release Name</th>
                <th width="250px">Release Status</th>
                <th width="250px">Release Create Date</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                @for (data of innerTable.data; track data) {
                  <tr>
                    <td>{{ data.name }}</td>
                    <td>
                      @switch (data.status) {
                        @case ('ACTIVE') {
                          <nz-tag class="btn-system">{{ data.status | titlecase }}</nz-tag>
                        }
                        @case ('INACTIVE') {
                          <nz-tag class="btn-system--normal">{{ data.status | titlecase }}</nz-tag>
                        }
                        @default {
                          <nz-tag [nzColor]="'rgb(245,157,110)'">{{ data.status  | titlecase }}</nz-tag>
                        }
                      }
                    </td>
                    <td>{{ data.createdDate  | date:'MMM d, y, h:mm a' }}</td>
                    <td>
                      <div class="d-flex " style="gap: 24px">
                        <button [routerLink]="['release',data.id,job.id]" nz-button><span nz-icon nzTheme="outline"
                                                                                          nzType="file-text"></span>Detail
                        </button>
                        <button (click)="onActivateRelease(data.id)" [disabled]="data.status === 'ACTIVE'"
                                nz-button><span
                          nz-icon
                          nzTheme="outline"
                          nzType="file-done"></span>Activate
                        </button>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </nz-table>
          </tr>
        }
      </tbody>
    </nz-table>
  </div>
</div>
