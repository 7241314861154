<nz-spin [nzSpinning]="loading" nzTip="Processing...">
  <div class="create-job-page">
    <div class="create-job-page__content">
      <div>
        <button (click)="onBack()" nz-button><span nz-icon nzTheme="outline" nzType="arrow-left"></span>Back</button>

      </div>
      <div class="app-text-header mt-16">
        New Career
      </div>

      <div class="create-job-page__form" nz-row nzGutter="24">
        <nz-col nzSpan="10">
          <div>
            <form (ngSubmit)="onCreate()" [formGroup]="form" nz-form nzLayout="vertical">
              <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="title" nzRequired>Career Title</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Title is required!">
                  <input formControlName="title" nz-input>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-col nzSpan="24" style="margin-bottom: 16px">
                  <div class="w-100 d-flex d-jus-between">
                    <div>Company</div>
                    <button nz-button routerLink="/company-list"><span nz-icon nzTheme="outline" nzType="plus"></span>Create
                      Company
                    </button>
                  </div>
                </nz-col>

                <nz-form-control [nzSpan]="24">
                  <nz-select formControlName="company">
                    @for (i of companies; track i.id) {
                      <nz-option [nzLabel]="i.name" [nzValue]="i.id"></nz-option>
                    }
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-card nzTitle="AI Generation">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="title">Language</nz-form-label>
                  <nz-form-control>
                    <nz-radio-group formControlName="language">
                      <label nz-radio nzValue="TH">TH</label>
                      <label nz-radio nzValue="EN">EN</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="title">Prompt</nz-form-label>
                  <nz-form-control [nzSpan]="24">
                <textarea formControlName="prompt" nz-input placeholder="write any thing"
                          rows="4"></textarea>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-col nzSpan="24">
                    <button (click)="onGenerate()" [disabled]="loading" [nzLoading]="loading" nz-button nzType="default"
                            style="width: 100%" type="button">
                      <span nz-icon nzTheme="outline" nzType="bulb"></span>
                      Generate
                    </button>
                  </nz-col>
                </nz-form-item>
              </nz-card>
            </form>
          </div>
        </nz-col>
        <nz-col nzSpan="14">
          <div>
            <form nz-form nzLayout="vertical">
              <nz-form-item nzGutter="8" style="margin-bottom:0;">
                <nz-form-label [nzSpan]="24" nzFor="title" nzRequired>Responsibilities</nz-form-label>
              </nz-form-item>
              @for (i of resArray.controls; track i; let index = $index) {
                <nz-form-item nzGutter="8">
                  <nz-form-control [nzSpan]="20" nzErrorTip="This field is requried!">
                    <input [formControl]="$any(i)" [disabled]="loading" nz-input>
                  </nz-form-control>
                  <nz-col nzSpan="4">
                    <div>
                      <button (click)="onResRemoveIndex(index)"
                              [disabled]="loading || resArray.controls.length === 1 "
                              nz-button style="width: 100%">
                        <span nz-icon nzType="delete" nzTheme="outline"></span>Delete
                      </button>
                    </div>
                  </nz-col>
                </nz-form-item>
              }

              <nz-form-item>
                <nz-col nzSpan="24">
                  <button (click)="onResAdd()" [disabled]="loading" nz-button nzType="default"
                          style="width: 100%"><span nz-icon nzTheme="outline" nzType="plus"></span> Add
                  </button>
                </nz-col>
              </nz-form-item>

              <nz-form-item nzGutter="8" style="margin-bottom:0;">
                <nz-form-label [nzSpan]="24" nzFor="title" nzRequired>Qualification</nz-form-label>
              </nz-form-item>
              @for (i of quaArray.controls; track i; let index = $index) {
                <nz-form-item nzGutter="8">
                  <nz-form-control [nzSpan]="20" nzErrorTip="This field is requried!">
                    <input [formControl]="$any(i)" [disabled]="loading" nz-input>
                  </nz-form-control>
                  <nz-col nzSpan="4">
                    <div>
                      <button (click)="onQuaRemoveIndex(index)"
                              [disabled]="loading || quaArray.controls.length === 1 "
                              nz-button style="width: 100%">
                        <span nz-icon nzType="delete" nzTheme="outline"></span>Delete
                      </button>
                    </div>
                  </nz-col>
                </nz-form-item>
              }

              <nz-form-item>
                <nz-col nzSpan="24">
                  <button (click)="onQualificationAdd()" [disabled]="loading" nz-button nzType="default"
                          style="width: 100%"><span nz-icon nzTheme="outline" nzType="plus"></span>Add
                  </button>
                </nz-col>
              </nz-form-item>
            </form>
          </div>
        </nz-col>
      </div>

    </div>

    <div class="action-panel">
      <button (click)="onCreate()" class="btn-system" nz-button nzSize="large" nzType="primary"><span nz-icon
                                                                                                      nzTheme="outline"
                                                                                                      nzType="plus"></span>Create
      </button>

    </div>
  </div>
</nz-spin>
