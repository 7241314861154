<div class="login-container">
  <div class="login-card">
    <div class="login-form">
      <h2>Sign In</h2>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>EMAIL</label>
          <input nz-input formControlName="email" placeholder="Email" />
        </div>

        <div class="form-group">
          <label>PASSWORD</label>
          <input nz-input type="password" formControlName="password" placeholder="Password" />
        </div>

        <div class="form-options">
          <label nz-checkbox>Remember Me</label>
          <a class="forgot-password">Forgot Password</a>
        </div>

        <button nz-button nzType="primary" [disabled]="loginForm.invalid">Sign In</button>
      </form>

      <div class="social-links">
        <a><i nz-icon nzType="google" nzTheme="outline"></i></a>
      </div>
    </div>

    <div class="welcome-section">
      <h2 class="text-white">Welcome to login</h2>
      <p>Don't have an account?</p>
      <a routerLink="/register" nz-button nzType="default">Sign Up</a>
    </div>
  </div>
</div>
