<div class="register-form">
  <div *ngIf="!jobLoading" class="register-form__content">
    <div class="register-form__content__banner">
      <img alt="" src="assets/banner.png">
    </div>
    <div class="register-form__content__job-title" id="stickyElement">
      <div class="f-24">
        <div>
          {{ jobDetail?.jobTitle }}
        </div>
        <div class="f-14 register-form__content__job-title__company">
          {{ jobDetail?.companyName }}
        </div>
      </div>
      <div>
        <button (click)="showModal()" class="animate-button">
          <span nz-icon nzType="form"></span>
          Register
        </button>
      </div>
    </div>
    <div class="register-form__content__job-description">


      <div class="f-24 f-c-title f-w-bold">
        Responsibilities
      </div>
      <div class="mt-8 f-16 f-c-sub-title f-w-400">
        <ul>
          @for (i of jobDetail?.resp; track i) {
            <li>{{ i }}</li>
          }
        </ul>
      </div>

      <div class="f-24 f-c-title mt-16 f-w-bold">
        Requirements
      </div>
      <div class="mt-8 f-16 f-w-400">
        <ul>
          @for (i of jobDetail?.jobQ; track i) {
            <li>{{ i }}</li>
          }
        </ul>
      </div>

      <div class="f-24 f-c-title f-w-bold mt-24">
        {{ jobDetail?.companyName }}
      </div>
      <div class="f-14 f-c-sub-title f-w-400">
        {{ jobDetail?.compDesc }}
      </div>

      <div class="mt-16 f-14">
        <fa-icon icon="location-dot"></fa-icon>
        <span style="padding-left: 6px">{{ jobDetail?.location }}</span>
      </div>
      <div class="mt-16 f-14">
        <fa-icon icon="phone-square"></fa-icon>
        <span style="padding-left: 6px">{{ jobDetail?.phoneNo }}</span>
      </div>
    </div>

  </div>
  <div *ngIf="jobLoading" class="register-form__content">
    <div class="skeleton-banner">
      <nz-skeleton-element
        [nzActive]="true"
        nzType="input"
        style="width:100%;"
      ></nz-skeleton-element>
    </div>

    <div class="mt-24 w-100">
      <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 8 }"></nz-skeleton>
    </div>

    <div class="mt-24 w-100">
      <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 8 }"></nz-skeleton>
    </div>
  </div>
</div>
<nz-modal
  (nzOnCancel)="handleCancel()"
  [(nzVisible)]="isVisible"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzTitle]="modalTitle"
>
  <ng-template #modalTitle>Register</ng-template>

  <ng-template #modalContent>
    <form [formGroup]="form" nz-form>
      <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>First Name</nz-form-label>
        <nz-form-control [nzSpan]="12" nzErrorTip="Please input your name!">
          <input [disabled]="isConfirmLoading" formControlName="firstName" nz-input placeholder="First Name"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>Last Name</nz-form-label>
        <nz-form-control [nzSpan]="12" nzErrorTip="Please input your name!">
          <input [disabled]="isConfirmLoading" formControlName="lastName" nz-input placeholder="Last Name"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>Mobile</nz-form-label>
        <nz-form-control [nzSpan]="12" nzErrorTip="Please input your phone number!">
          <input [disabled]="isConfirmLoading" formControlName="phoneNo" nz-input placeholder="phoneNo"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>E-mail</nz-form-label>
        <nz-form-control [nzSpan]="12" nzErrorTip="Please input your email!">
          <input [disabled]="isConfirmLoading" formControlName="email" nz-input placeholder="email" type="email"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>
          Resume
        </nz-form-label>
        <nz-form-control>
          <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" [nzDisabled]="isConfirmLoading"
                     nzAccept=".pdf" nzLimit="1">
            <button [disabled]="isConfirmLoading" nz-button type="button">
              <span nz-icon nzType="upload"></span>
              Select File
            </button>
          </nz-upload>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>
          Image
        </nz-form-label>
        <nz-form-control>
          <nz-upload [(nzFileList)]="images" [nzBeforeUpload]="beforeImageUpload" [nzDisabled]="isConfirmLoading"
                     nzLimit="1" nzListType="picture">
            <button [disabled]="isConfirmLoading" nz-button type="button">
              <span nz-icon nzType="upload"></span>
              Select File
            </button>
          </nz-upload>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button (click)="handleCancel()" [disabled]="isConfirmLoading" nz-button nzType="default">Close</button>
    <button (click)="handleOk()" [disabled]="!form.valid || fileList.length==0 || images.length==0"
            [nzLoading]="isConfirmLoading"
            nz-button
            nzType="primary">
      Submit
    </button>
  </ng-template>
</nz-modal>
