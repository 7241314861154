import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {GetInterviewQuestionResponse, UpdateCommentPayload} from "./interview.model";


@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  constructor(
    private http: HttpClient
  ) {
  }

  comment(id: string, text: string): Observable<any> {
    const url = environment.apiUrl + `/applicants/${id}/comment`;
    return this.http.post(url, {
      comment: text,
    })
  }

  updateComment(payload: UpdateCommentPayload): Observable<void> {
    const url = environment.apiUrl + `/applicants/${payload.applicantId}/comment/${payload.id}`;
    return this.http.put<void>(url, {
      comment: payload.comment,
    })
  }

  deleteComment(payload: Omit<UpdateCommentPayload, 'comment'>): Observable<void> {
    const url = environment.apiUrl + `/applicants/${payload.applicantId}/comment/${payload.id}`;
    return this.http.delete<void>(url)
  }

  getInterviewQuestion(id: string, lang: string): Observable<GetInterviewQuestionResponse> {
    const url = environment.apiUrl + `/applicants/${id}/interviewer-question`;
    return this.http.post<GetInterviewQuestionResponse>(url, {
      language: lang,
    })
  }

  changeStatus(id: string, status: string): Observable<void> {
    const url = environment.apiUrl + `/applicants/${id}/update-status`;
    return this.http.post<void>(url, {
      status
    })
  }

}
