import { Injectable } from '@angular/core';
import {
  CreateJobAssessPayload,
  CreateJobAssessResponse,
  CreateReleasePayload,
  CreateReleaseResponse,
  GenerateJobAssessPayload,
  GenerateJobAssessResponse,
  GetAllJobAssessByJobIdResponse,
  GetExaminationResponse,
  getJobAssessReleaseIdByJobIdResponse,
  GetReleaseListByJobIdResponse,
  UpdateJobAssessPayload,
  UpdateJobAssessResponse,
  UpdateJovReleaseVersionPayload,
} from './examination.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExaminationService {
  constructor(private http: HttpClient) {}

  generateExamination(payload: GenerateJobAssessPayload): Observable<GenerateJobAssessResponse> {
    const url = environment.apiUrl + `/job-assess/${payload.jobAssessId}/generated`;
    return this.http.post<GenerateJobAssessResponse>(url, payload);
  }

  createRelease(payload: CreateReleasePayload): Observable<CreateReleaseResponse> {
    const url = environment.apiUrl + '/job-assess-vers';
    return this.http.post<CreateReleaseResponse>(url, payload);
  }

  getReleaseListByJobId(id: string): Observable<GetReleaseListByJobIdResponse> {
    const url = environment.apiUrl + `/job-assess-vers?jobDescId=${id}`;
    return this.http.get<GetReleaseListByJobIdResponse>(url);
  }

  createJobAssess(payload: CreateJobAssessPayload): Observable<CreateJobAssessResponse> {
    const url = environment.apiUrl + `/job-assess`;
    return this.http.post<CreateJobAssessResponse>(url, payload);
  }

  updateJobAssess(payload: UpdateJobAssessPayload): Observable<UpdateJobAssessResponse> {
    const url = environment.apiUrl + `/job-assess/${payload.id}`;
    return this.http.put<UpdateJobAssessResponse>(url, {
      name: payload.name,
      totalTime: payload.totalTime,
      questions: payload.questions,
    });
  }

  getAllJobAssessByJobId(id: string): Observable<GetAllJobAssessByJobIdResponse> {
    const url = environment.apiUrl + `/job-assess/by-job-desc/${id}`;
    return this.http.get<GetAllJobAssessByJobIdResponse>(url);
  }

  getJobAssessReleaseIdByJobId(
    releaseId: string,
    jobId: string
  ): Observable<getJobAssessReleaseIdByJobIdResponse> {
    const url = environment.apiUrl + `/job-assess/selected`;
    return this.http.post<getJobAssessReleaseIdByJobIdResponse>(url, {
      jobDescId: jobId,
      jobAssessVerId: releaseId,
    });
  }

  updateReleaseVersion(payload: UpdateJovReleaseVersionPayload): Observable<string> {
    const url = environment.apiUrl + `/job-assess-vers/${payload.id}`;
    return this.http.put<string>(url, {
      versionName: payload.versionName,
      jobAssessIds: payload.jobAssessIds,
    });
  }

  getExamination(id: string): Observable<GetExaminationResponse> {
    const url = environment.apiUrl + `/job-assess/${id}`;
    return this.http.get<GetExaminationResponse>(url);
  }

  activateRelease(id: string): Observable<string> {
    const url = environment.apiUrl + `/job-assess-vers/${id}/activated`;
    return this.http.patch<string>(url, {});
  }
}
