import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Exams, GetExamsInformation, RetestResponse} from "./test.model";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(
    private http: HttpClient
  ) {
  }

  getExamsInformation(token: string, retest?: boolean, id?: string): Observable<GetExamsInformation> {
    const url = environment.apiUrl + '/applicantAssess/get-job-assess-overview';
    return this.http.post<GetExamsInformation>(url, !retest ? {
      token
    } : {
      applicantId: id,
      isRetest: true
    })
  }


  startExams(token: string, retest?: boolean, id?: string): Observable<void> {
    const url = environment.apiUrl + '/applicantAssess/start-job-assess';
    return this.http.post<void>(url, !retest ? {
      token
    } : {
      applicantId: id,
      isRetest: true
    })
  }

  getExams(token: string, retest?: boolean, id?: string): Observable<Exams> {
    const url = environment.apiUrl + '/applicantAssess/get-job-assess';
    return this.http.post<Exams>(url, !retest ? {
      token
    } : {
      applicantId: id,
      isRetest: true
    })
  }

  submit(token: string, submitJobAssessAns: Record<string, string>, retest?: boolean, id?: string): Observable<void> {
    const url = environment.apiUrl + '/applicantAssess/submit-job-assess';
    return this.http.post<void>(url, {
      token,
      submitJobAssessAns
    })
  }

  retestSubmit(submitJobAssessAns: Record<string, string>, id: string): Observable<RetestResponse> {
    const url = environment.apiUrl + '/applicantAssess/submit-retest-job-assess';
    return this.http.post<RetestResponse>(url, {
      applicantId: id,
      submitJobAssessAns
    })
  }
}
