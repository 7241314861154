import {Component, OnInit} from '@angular/core';
import {NzProgressComponent} from "ng-zorro-antd/progress";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-retest-result',
  standalone: true,
  imports: [
    NzProgressComponent
  ],
  templateUrl: './retest-result.component.html',
  styleUrl: './retest-result.component.scss'
})
export class RetestResultComponent implements OnInit {
  score: number = 0;

  constructor(private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    const data = JSON.parse(atob(this.route.snapshot.queryParams['p']));
    this.score = parseInt(data)
  }

}
