import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {
  private history: string[] = [];
  private currentIndex: number = -1;

  constructor(private router: Router, private location: Location, private route: ActivatedRoute) {

    this.router.events
      .subscribe({
        next: (event) => {
          
          if (event instanceof NavigationEnd) {
            console.log(event)
            this.addToHistory(event.urlAfterRedirects);
          }
        }
      });
  }

  back(): void {
    if (this.canGoBack()) {
      this.currentIndex--;
      console.log(this.history[this.currentIndex])
      this.router.navigateByUrl(this.history[this.currentIndex]);
    }
  }

  next(): void {
    if (this.canGoForward()) {
      this.currentIndex++;
      this.router.navigateByUrl(this.history[this.currentIndex]);
    }
  }

  remove(index: number): void {
    if (index >= 0 && index < this.history.length) {
      this.history.splice(index, 1);
      if (this.currentIndex >= index) {
        this.currentIndex = Math.max(0, this.currentIndex - 1);
      }
    }
  }

  removeOne(): void {
    this.remove(this.getHistory().length - 1)
  }

  canGoBack(): boolean {
    return this.currentIndex > 0;
  }

  canGoForward(): boolean {
    return this.currentIndex < this.history.length - 1;
  }

  getHistory(): string[] {
    return [...this.history]; // Return a copy of the history array
  }

  private addToHistory(url: string) {
    if (this.currentIndex === -1 || this.history[this.currentIndex] !== url) {
      // Remove any forward history if we're navigating
      this.history = this.history.slice(0, this.currentIndex + 1);
      this.history.push(url);
      this.currentIndex++;
    }
  }
}
