import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TestService } from '../../core/services/test/test.service';
import { NotificationService } from '../../shared/services/notification/notification.service';

interface ExamsInformation {
  jobTitle: string;
  companyName: string;
  applicantName: string;
  totalTimeStr: string;
  totalTimeSeconds: number;
  totalSetQuestion: number;
  totalQuestion: number;
  minutes: number;
}

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss',
})
export class TestComponent implements OnInit {
  information?: ExamsInformation;
  token: string = '';
  mode = 0;

  constructor(
    private route: ActivatedRoute,
    private testService: TestService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.mode = parseInt(this.route.snapshot.queryParams['f']) || 0;
    this.token = this.route.snapshot.queryParams['token']
      ? decodeURIComponent(this.route.snapshot.queryParams['token'])
      : this.route.snapshot.queryParams['token'];
    if (this.token) {
      console.log(this.token);
      this.testService.getExamsInformation(this.token).subscribe({
        next: res => {
          this.information = {
            ...res.data,
            minutes: res.data.totalTimeSeconds / 60,
          };
        },
        error: err => {
          this.notificationService.apiError(err);
        },
      });
    } else if (this.mode === 1) {
      const id = this.route.snapshot.queryParams['id'];
      this.testService.getExamsInformation('', true, id).subscribe({
        next: res => {
          this.information = {
            ...res.data,
            minutes: res.data.totalTimeSeconds / 60,
          };
        },
        error: err => {
          this.notificationService.apiError(err);
        },
      });
    }
  }

  onStart() {
    console.log(this.mode);
    if (this.mode === 0) {
      this.testService.startExams(this.token).subscribe({
        next: () => {
          this.router.navigate(['/test/exams'], {
            queryParams: {
              token: this.token,
            },
          });
        },
        error: e => {
          this.notificationService.apiError(e);
        },
      });
    } else {
      const id = this.route.snapshot.queryParams['id'];
      this.testService.startExams('', true, id).subscribe({
        next: () => {
          this.router.navigate(['/test/exams'], {
            queryParams: {
              id: id,
              f: this.mode,
            },
          });
        },
        error: e => {
          this.notificationService.apiError(e);
        },
      });
    }
  }
}
