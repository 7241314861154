import { Component, OnInit } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { RegisterService } from '../../core/services/register/register.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobService } from '../../core/services/job/job.service';
import { NgIf } from '@angular/common';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceItemDirective } from 'ng-zorro-antd/space';
import { NotificationService } from '../../shared/services/notification/notification.service';

interface JobDetail {
  id: string;
  jobTitle: string;
  companyId: string;
  companyName: string;
  location: string;
  phoneNo: string;
  compDesc: string;
  resp: string[];
  jobQ: string[];
}

@Component({
  selector: 'app-register-job',
  standalone: true,
  imports: [
    NzButtonModule,
    NzIconDirective,
    FaIconComponent,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    FormsModule,
    NzUploadModule,
    ReactiveFormsModule,
    NgIf,
    NzSkeletonModule,
    NzSpaceItemDirective,
  ],
  templateUrl: './register-job.component.html',
  styleUrl: './register-job.component.scss',
})
export class RegisterJobComponent implements OnInit {
  isVisible = false;
  isConfirmLoading = false;
  form: FormGroup;
  fileList: NzUploadFile[] = [];
  images: NzUploadFile[] = [];
  jobDetail?: JobDetail;
  jobLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private jobService: JobService,
    private router: Router,
    private route: ActivatedRoute,
    private notification: NotificationService
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phoneNo: ['', Validators.required],
    });
  }

  disableAll() {
    Object.values(this.form.controls).forEach(control => {
      if (control instanceof FormArray) {
        Object.values(control.controls).forEach(i => {
          i.disable();
        });
      } else if (control instanceof FormControl) {
        control.disable();
      }
    });
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  beforeImageUpload = (file: NzUploadFile): boolean => {
    this.images = this.images.concat(file);
    return false;
  };

  ngOnInit() {
    this.initSticky();
    this.jobLoading = true;
    this.jobService.getJobForPreview(this.route.snapshot.params['id']).subscribe({
      next: value => {
        this.jobDetail = value.data;
        this.jobLoading = false;
      },
      error: e => {
        this.notification.apiError(e);
      },
    });
  }

  initSticky() {
    window.addEventListener('scroll', function () {
      const stickyElement = document.getElementById('stickyElement');
      const stickyPosition = stickyElement?.getBoundingClientRect().top;

      if (stickyPosition !== undefined && stickyPosition !== null && stickyPosition <= 0) {
        stickyElement?.classList.add('sticky-active');
      } else {
        stickyElement?.classList.remove('sticky-active');
      }
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.submit();
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  private submit(): void {
    if (this.form.valid) {
      this.disableAll();
      this.isConfirmLoading = true;
      this.registerService
        .register({
          file: this.fileList[0],
          image: this.images[0],
          data: {
            jobDescId: this.route.snapshot.params['id'],
            firstName: this.form.value.firstName,
            lastName: this.form.value.lastName,
            phoneNo: this.form.value.phoneNo,
            email: this.form.value.email,
          },
        })
        .subscribe({
          next: () => {
            this.isConfirmLoading = false;
            this.router.navigate(['/result'], {
              queryParams: {
                status: 'CANDIDATE_SUBMITTED',
              },
            });
          },
          error: e => {
            this.notification.apiError(e);
            this.isConfirmLoading = false;
            this.form.enable();
          },
        });
    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
