import { Routes } from '@angular/router';
import { RegisterJobComponent } from './pages/register-job/register-job.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { MainLayoutComponent } from './pages/layouts/main-layout/main-layout.component';
import { CandidateComponent } from './pages/candidate/candidate.component';
import { ResultComponent } from './pages/result/result.component';
import { CompanyListComponent } from './pages/company-list/company-list.component';
import { JobListComponent } from './pages/job/job-list/job-list.component';
import { CreateJobComponent } from './pages/job/create-job/create-job.component';
import { EditJobComponent } from './pages/job/edit-job/edit-job.component';
import { ReleaseListComponent } from './pages/examination/release-list/release-list.component';
import { CreateReleaseComponent } from './pages/examination/create-release/create-release.component';
import { CreateExaminationComponent } from './pages/examination/create-examination/create-examination.component';
import { EditExaminationComponent } from './pages/examination/edit-examination/edit-examination.component';
import { EditReleaseComponent } from './pages/examination/edit-release/edit-release.component';
import { TestComponent } from './pages/test/test.component';
import { ExamsComponent } from './pages/test/exams/exams.component';
import { RetestResultComponent } from './pages/test/retest-result/retest-result.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'register/:id',
    component: RegisterJobComponent,
  },
  {
    path: 'result',
    component: ResultComponent,
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'company-list',
        pathMatch: 'full',
      },
      {
        path: 'ranking/:id',
        component: RankingComponent,
      },
      {
        path: 'candidate/:id',
        component: CandidateComponent,
      },
      {
        path: 'company-list',
        component: CompanyListComponent,
      },
      {
        path: 'career',
        component: JobListComponent,
      },
      {
        path: 'career/create',
        component: CreateJobComponent,
      },
      {
        path: 'career/edit/:id',
        component: EditJobComponent,
      },
      {
        path: 'examination',
        component: ReleaseListComponent,
      },
      {
        path: 'examination/release/:id/create',
        component: CreateReleaseComponent,
      },
      {
        path: 'examination/create/:jobId/:releaseId',
        component: CreateExaminationComponent,
      },
      {
        path: 'examination/edit/:id/:jobId/:releaseId',
        component: EditExaminationComponent,
        pathMatch: 'full',
      },
      {
        path: 'examination/release/:id/:jobId',
        component: EditReleaseComponent,
      },
    ],
  },
  {
    path: 'test',
    component: TestComponent,
  },
  {
    path: 'test/exams',
    component: ExamsComponent,
  },
  {
    path: 'test/retest/result',
    component: RetestResultComponent,
  },
];
