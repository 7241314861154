import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgForOf } from '@angular/common';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { QuestionCardComponent } from './question-card/question-card.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, Router } from '@angular/router';
import { TestService } from '../../../core/services/test/test.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { Exams, JobAssessList } from '../../../core/services/test/test.model';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';

interface Question {
  id: string;
  runningNumber: number;
  question: string;
  choices: Choice[];
}

interface Choice {
  id: string;
  label: string;
}

interface ExamsInformation {
  totalTimeStr: string;
  totalTimeSeconds: number;
  startAssessDateTime: string;
  remainingTimeSeconds: number;
}

@Component({
  selector: 'app-exams',
  standalone: true,
  imports: [
    NzFormModule,
    NzGridModule,
    NzRadioModule,
    ReactiveFormsModule,
    NgForOf,
    QuestionCardComponent,
    CountdownTimerComponent,
    NzButtonModule,
    NzModalModule,
  ],
  templateUrl: './exams.component.html',
  styleUrl: './exams.component.scss',
})
export class ExamsComponent implements OnInit {
  exams: Question[] = [];
  currentQuestionIndex = 0;
  form: FormGroup;
  token = '';
  examsInformation?: ExamsInformation;
  mode = 0;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private testService: TestService,
    private notificationService: NotificationService,
    private nzModalService: NzModalService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      exams: this.formBuilder.array([]),
    });
    this.exams = [];
  }

  get examFormArray(): FormArray {
    return this.form.get('exams') as FormArray;
  }

  ngOnInit() {
    this.mode = parseInt(this.route.snapshot.queryParams['f']) || 0;
    this.token = this.route.snapshot.queryParams['token']
      ? decodeURIComponent(this.route.snapshot.queryParams['token'])
      : this.route.snapshot.queryParams['token'];

    console.log(this.token);
    if (this.token) {
      this.testService.getExams(this.token).subscribe({
        next: response => {
          const data = response.data;
          this.exams = this.mapJobAssessList(data.jobAssessList);
          this.examsInformation = {
            remainingTimeSeconds: data.remainingTimeSeconds,
            startAssessDateTime: data.startAssessDateTime,
            totalTimeSeconds: data.totalTimeSeconds,
            totalTimeStr: data.totalTimeStr,
          };
          this.buildForm();
        },
        error: e => {
          this.notificationService.apiError(e);
        },
      });
    } else if (this.mode === 1) {
      const id = this.route.snapshot.queryParams['id'];
      this.testService.getExams('', true, id).subscribe({
        next: response => {
          const data = response.data;
          this.exams = this.mapJobAssessList(data.jobAssessList);
          this.examsInformation = {
            remainingTimeSeconds: data.remainingTimeSeconds,
            startAssessDateTime: data.startAssessDateTime,
            totalTimeSeconds: data.totalTimeSeconds,
            totalTimeStr: data.totalTimeStr,
          };
          this.buildForm();
        },
        error: e => {
          this.notificationService.apiError(e);
        },
      });
    }

    this.buildForm();
  }

  onQuestionClicked(index: number) {
    this.currentQuestionIndex = index;
  }

  onSubmit() {
    this.nzModalService.confirm({
      nzTitle: 'ยืนยันการส่งข้อสอบ',
      nzContent:
        'กรุณาตรวจสอบข้อสอบของคุณให้เรียบร้อยก่อนยืนยัน \n ถ้ายืนยันการส่งข้อสอบจะไม่สามารถกลับมาแก้ข้อสอบได้อีก',
      nzOkText: 'ยืนยัน',
      nzCancelText: 'ยกเลิก',
      nzOnOk: () => {
        const answer: Record<string, string> = {};
        this.form.value.exams.forEach((item: any) => {
          answer[item.id] = item.selected;
        });
        if (this.mode === 0) {
          this.testService.submit(this.token, answer).subscribe({
            next: () => {
              this.router.navigate(['/result'], {
                queryParams: {
                  status: 'EXAMS_SUBMITTED',
                },
              });
            },
            error: e => {
              this.notificationService.apiError(e);
            },
          });
        } else {
          const id = this.route.snapshot.queryParams['id'];
          this.testService.retestSubmit(answer, id).subscribe({
            next: response => {
              const data = response.data;
              this.router.navigate(['/test/retest/result'], {
                queryParams: {
                  p: btoa(JSON.stringify(data.examScorePercentage)),
                },
              });
            },
            error: e => {
              this.notificationService.apiError(e);
            },
          });
        }
      },
      nzOnCancel: () => {},
    });
  }

  onNextChoice() {
    if (this.currentQuestionIndex < this.exams.length - 1) {
      this.currentQuestionIndex += 1;
    }
  }

  onPrevChoice() {
    this.currentQuestionIndex -= 1;
  }

  private buildForm() {
    this.exams.forEach(i => {
      const ar = this.form.get('exams') as FormArray;
      ar.push(
        this.formBuilder.group({
          id: i.id,
          selected: '',
        })
      );
    });
  }

  private mapJobAssessList(jobAssessList: JobAssessList[]): Question[] {
    return jobAssessList.flatMap(assess =>
      assess.questions.map((question, index) => ({
        id: question.jobAssessChoiceId,
        runningNumber: index + 1,
        question: question.questionName,
        choices: Object.entries(question.choice).map(([key, value]) => ({
          id: key,
          label: value,
        })),
      }))
    );
  }
}
