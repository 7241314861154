import {Component, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzTableModule, NzTableSortFn} from "ng-zorro-antd/table";
import {RouterLink} from "@angular/router";
import {CompanyService} from "../../core/services/company/company.service";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {finalize} from "rxjs";
import {NotificationService} from "../../shared/services/notification/notification.service";
import {NzIconDirective} from "ng-zorro-antd/icon";

interface ListOfColumn {
  title: string;
  priority: boolean | number;
  showSort: boolean;
  compare: NzTableSortFn<any> | null;
  align: 'left' | 'center' | 'right' | null;
  width: string | null;
}

interface DataItem {
  companyId: string
  companyName: string
  location: string
  phoneNo: string
  compDesc: string
}

@Component({
  selector: 'app-company-list',
  standalone: true,
  imports: [
    NgForOf,
    NzButtonModule,
    NzTableModule,
    RouterLink,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzIconDirective,
  ],
  templateUrl: './company-list.component.html',
  styleUrl: './company-list.component.scss'
})
export class CompanyListComponent implements OnInit {
  isVisible = false;
  isConfirmLoading = false;
  form: FormGroup;

  listOfColumn: ListOfColumn[] = [
    {
      title: 'No',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: '100px'
    },
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.companyName.localeCompare(b.companyName),
      priority: false,
      showSort: false,
      align: null,
      width: null
    },
    {
      title: '',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: '250px'
    }
  ];
  listOfData: DataItem[] = [];
  loading = false

  constructor(
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private notification: NotificationService
  ) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      location: ['', Validators.required],
      phoneNo: ['', Validators.required],
      compDesc: ['', Validators.required],
    })
  }

  ngOnInit() {
    this.fetchCompaniesData();
  }

  onCreate() {
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
  }

  onSubmit() {
    if (this.form.valid) {
      this.isConfirmLoading = false;
      this.companyService.createCompany({
        companyName: this.form.value.name,
        compDesc: this.form.value.compDesc,
        location: this.form.value.location,
        phoneNo: this.form.value.phoneNo
      }).pipe(
        finalize(() => {
          this.fetchCompaniesData();
          this.isConfirmLoading = false;
          this.isVisible = false;
        })
      ).subscribe({
        complete: () => {
          this.notification.success(
            'Create Company Successfully',
            '');
        }, error: (e) => {
          this.notification.apiError(e);
        }

      })
    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }

  }

  private fetchCompaniesData() {
    this.companyService.companyList().subscribe({
      next: (list) => {
        this.listOfData = list;
      }
    })
  }
}
