<div style="margin-bottom: 16px">
  <button (click)="onBack()" nz-button><span nz-icon nzTheme="outline" nzType="arrow-left"></span>Back</button>
</div>
<div class="release-list">
  <div class="release-list__content">
    <div class="d-flex d-jus-between">
      <div><h2>New Release</h2></div>
    </div>

    <div>
      <form [formGroup]="form" nz-form>
        <nz-form-item>
          <nz-form-label nzRequired>Release Name</nz-form-label>
          <nz-form-control nzErrorTip="Release name is required">
            <input formControlName="name" nz-input type="text">
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>


    <!--    <div class="d-flex d-jus-between">-->
    <!--      <div>-->
    <!--        <h3>Examination List</h3>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <button [routerLink]="['/examination/create',jobId]" nz-button nzSize="default"><span nz-icon nzTheme="outline"-->
    <!--                                                                                              nzType="plus"></span>Create-->
    <!--          New-->
    <!--          Examination-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <nz-table #sortTable [nzData]="listOfData" [nzLoading]="loading" class="mt-24" nzTableLayout="fixed">-->
    <!--      <thead>-->
    <!--      <tr>-->
    <!--        <th-->
    <!--          (nzCheckedChange)="onAllChecked($event)"-->
    <!--          [nzChecked]="checked"-->
    <!--          [nzIndeterminate]="indeterminate"-->
    <!--          nzLabel="Select all"-->
    <!--          nzWidth="50px"-->
    <!--        ></th>-->
    <!--        <th nzAlign="left">Examination Name</th>-->
    <!--        <th nzAlign="center">Questions</th>-->
    <!--        <th></th>-->
    <!--      </tr>-->
    <!--      </thead>-->
    <!--      <tbody>-->
    <!--      <tr *ngFor="let data of sortTable.data">-->
    <!--        <td-->
    <!--          (nzCheckedChange)="onItemChecked(data.id, $event)"-->
    <!--          [nzChecked]="setOfCheckedId.has(data.id)"-->
    <!--          [nzLabel]="data.name"-->
    <!--        ></td>-->
    <!--        <td>{{ data.name }}</td>-->
    <!--        <td [nzAlign]="'center'">-->
    <!--          {{ data.totalNoQuestion }}-->
    <!--        </td>-->
    <!--        <td [nzAlign]="'center'">-->
    <!--          <button nz-button><span nz-icon nzTheme="outline"-->
    <!--                                  nzType="file-text"></span>Detail-->
    <!--          </button>-->
    <!--        </td>-->

    <!--      </tr>-->
    <!--      </tbody>-->
    <!--    </nz-table>-->
    <div class="mt-24 w-100 d-flex d-jus-end">
      <button (click)="onSubmit()" class="btn-system" nz-button nzSize="large" nzType="primary"><span nz-icon
                                                                                                      nzTheme="outline"
                                                                                                      nzType="plus"></span>Create
      </button>
    </div>
  </div>
</div>
